import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./invoicing.css";
import { FaPlus } from "react-icons/fa";
import { postData } from "../../utils/api";
import { toast } from "react-toastify";
import { saveMultiPagePDF } from "../../utils/storePDF";
import { generateInvoiceHTML } from "./InvoicePDF";
import Loader from "../loader/Loader";

const InvoiceTemplate = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [invoiceData, setInvoiceData] = useState({
        invoiceNumber: "IND/24-25/00X",
        invoiceDate: "",
        dueDate: "",
        currency: "INR",
        notes: "",
        from: {
            Country: "",
            Name: "",
            BusinessName: "",
            GSTIN: "",
            Address: "",
            City: "",
            State: "",
            Email: "",
            Phone: "",
        },
        to: {
            Country: "",
            Name: "",
            BusinessName: "",
            GSTIN: "",
            Address: "",
            City: "",
            State: "",
            Email: "",
            Phone: "",
        },
        items: [
            {
                name: "",
                quantity: "",
                price: "",
                tax: "",
                amount: "",
            },
            {
                name: "",
                quantity: "",
                price: "",
                tax: "",
                amount: "",
            },
            {
                name: "",
                quantity: "",
                price: "",
                tax: "",
                amount: "",
            },
        ],
        discount: "",
    });

    const currency = {
        INR: "₹",
        USD: "$",
        EURO: "€",
        POUND: "£",
    };

    const handleTextArea = (e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const handleInputChange = (e, section, key, index) => {
        if (section === "items") {
            const updatedItems = [...invoiceData.items];
            updatedItems[index][key] = e.target.value;
            setInvoiceData({ ...invoiceData, items: updatedItems });
        } else if (section) {
            setInvoiceData({
                ...invoiceData,
                [section]: { ...invoiceData[section], [key]: e.target.value },
            });
        } else {
            setInvoiceData({ ...invoiceData, [key]: e.target.value });
        }
    };

    const addItem = () => {
        setInvoiceData({
            ...invoiceData,
            items: [
                ...invoiceData.items,
                {
                    name: "",
                    quantity: "",
                    price: "",
                    tax: "",
                    amount: "",
                },
            ],
        });
    };

    const handleRemoveItem = (key) => {
        let newInvoiceData = invoiceData.items.filter(
            (invoice, index) => key != index
        );

        setInvoiceData({ ...invoiceData, items: newInvoiceData });
    };

    const handleSubmit = async () => {
        document.getElementsByClassName(
            "invoiceFooterCTA"
        )[0].ariaDisabled = true;
        let subtotal = 0,
            tax = 0;

        let invoiceItems = [];
        invoiceData.items.map((item) => {
            subtotal += Number(item.price * item.quantity);
            tax += Number(
                (item.tax / 100) *
                    (item.quantity * item.price -
                        (invoiceData.discount / 100) *
                            item.quantity *
                            item.price)
            );
            if (item.name.trim() != "") {
                invoiceItems = [
                    ...invoiceItems,
                    {
                        name: item.name,
                        quantity: item.quantity,
                        price: item.price,
                        tax_field: item.tax,
                        tax_value:
                            (item.tax / 100) *
                            (item.quantity * item.price -
                                (invoiceData.discount / 100) *
                                    item.quantity *
                                    item.price),
                        total_cost:
                            Number(item.price) +
                            Number((item.tax / 100) * item.price),
                    },
                ];
            }
        });
        const serverData = {
            invoice: {
                invoice_number: invoiceData.invoiceNumber,
                invoice_date: invoiceData.invoiceDate,
                due_date: invoiceData.dueDate,
                currency: invoiceData.currency,
                invoice_untaxed_amount: subtotal,
                invoice_total_tax: tax,
                invoice_discount: Number(
                    (invoiceData.discount / 100) * subtotal
                ),
                invoice_total_cost:
                    subtotal -
                    Number((invoiceData.discount / 100) * subtotal) +
                    tax,
                note: invoiceData.notes,
            },
            user: {
                name: invoiceData.from.Name,
                business_name: invoiceData.from.BusinessName,
                gstin: invoiceData.from.GSTIN,
                address: invoiceData.from.Address,
                city: invoiceData.from.City,
                state: invoiceData.from.State,
                email: invoiceData.from.Email,
                country: invoiceData.from.Country,
                client: 0,
            },
            client: {
                name: invoiceData.to.Name,
                business_name: invoiceData.to.BusinessName,
                gstin: invoiceData.to.GSTIN,
                address: invoiceData.to.Address,
                city: invoiceData.to.City,
                state: invoiceData.to.State,
                email: invoiceData.to.Email,
                country: invoiceData.to.Country,
                client: 1,
            },
            items: invoiceItems,
        };

        const response = await postData("/create-invoice", serverData);

        if (response.error) {
            toast.error(response.error);
            document.getElementsByClassName(
                "invoiceFooterCTA"
            )[0].ariaDisabled = false;
            return;
        }

        toast.success("Invoice created successfully");
        navigate("/invoice/list");
    };

    const handleSave = async () => {
        setIsLoading(true);
        let tempItems = invoiceData.items.filter(
            (item) => item.name.trim() !== ""
        );
        let invoiceDataTemp = { ...invoiceData, items: tempItems };
        const pdfTemplate = generateInvoiceHTML(invoiceDataTemp);
        await saveMultiPagePDF(pdfTemplate, invoiceData.invoiceNumber);
        setIsLoading(false);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="invoiceTemplate invoiceSidebarContainer">
                    <div className="invoiceContainer ">
                        <div className="invoiceHeader">
                            <div className="invoiceHeaderDetails">
                                <div>
                                    <h3>Customer Invoice</h3>
                                    <input
                                        className="invoiceNumber"
                                        required
                                        value={invoiceData.invoiceNumber}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                null,
                                                "invoiceNumber"
                                            )
                                        }
                                    />
                                </div>
                                <div className="invoiceHeaderDates">
                                    <label>
                                        Currency
                                        <select
                                            name="currency"
                                            value={invoiceData.currency}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    null,
                                                    "currency"
                                                )
                                            }
                                        >
                                            <option value="INR">INR ₹</option>
                                            <option value="USD">USD $</option>
                                            <option value="EURO">EURO €</option>
                                            <option value="POUND">
                                                POUND £
                                            </option>
                                        </select>
                                    </label>
                                    <label>
                                        Invoice Date
                                        <input
                                            type="date"
                                            value={invoiceData.invoiceDate}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    null,
                                                    "invoiceDate"
                                                )
                                            }
                                        />
                                    </label>
                                    <label>
                                        Due Date
                                        <input
                                            type="date"
                                            value={invoiceData.dueDate}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    null,
                                                    "dueDate"
                                                )
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="invoiceParties">
                            <div className="invoiceParty">
                                <h3>Billed By:</h3>
                                <div className="invoicePartyDetails">
                                    <input
                                        type="text"
                                        placeholder="Business name"
                                        value={invoiceData.from["BusinessName"]}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "from",
                                                "BusinessName"
                                            )
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={invoiceData.from["Name"]}
                                        onChange={(e) =>
                                            handleInputChange(e, "from", "Name")
                                        }
                                    />
                                    <div className="invoicePartyDetailsHalfInputContainer">
                                        <input
                                            type="number"
                                            min={0}
                                            placeholder="Phone"
                                            value={invoiceData.from["Phone"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "from",
                                                    "Phone"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                        <input
                                            type="text"
                                            placeholder="GSTIN"
                                            maxLength={15}
                                            value={invoiceData.from["GSTIN"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "from",
                                                    "GSTIN"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        value={invoiceData.from["Email"]}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "from",
                                                "Email"
                                            )
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder="Address"
                                        value={invoiceData.from["Address"]}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "from",
                                                "Address"
                                            )
                                        }
                                    />
                                    <div className="invoicePartyDetailsHalfInputContainer">
                                        <input
                                            type="text"
                                            placeholder="City"
                                            value={invoiceData.from["City"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "from",
                                                    "City"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                        <input
                                            type="text"
                                            placeholder="State"
                                            value={invoiceData.from["State"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "from",
                                                    "State"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                    </div>
                                    <input
                                        placeholder="Country"
                                        type="text"
                                        value={invoiceData.from["Country"]}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "from",
                                                "Country"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="invoiceParty">
                                <h3>Billed To:</h3>
                                <div className="invoicePartyDetails">
                                    <input
                                        type="text"
                                        placeholder="Business name"
                                        value={invoiceData.to["BusinessName"]}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "to",
                                                "BusinessName"
                                            )
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={invoiceData.to["Name"]}
                                        onChange={(e) =>
                                            handleInputChange(e, "to", "Name")
                                        }
                                    />
                                    <div className="invoicePartyDetailsHalfInputContainer">
                                        <input
                                            type="number"
                                            min={0}
                                            placeholder="Phone"
                                            value={invoiceData.to["Phone"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "to",
                                                    "Phone"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                        <input
                                            type="text"
                                            placeholder="GSTIN"
                                            maxLength={15}
                                            value={invoiceData.to["GSTIN"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "to",
                                                    "GSTIN"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        value={invoiceData.to["Email"]}
                                        onChange={(e) =>
                                            handleInputChange(e, "to", "Email")
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder="Address"
                                        value={invoiceData.to["Address"]}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "to",
                                                "Address"
                                            )
                                        }
                                    />
                                    <div className="invoicePartyDetailsHalfInputContainer">
                                        <input
                                            type="text"
                                            placeholder="City"
                                            value={invoiceData.to["City"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "to",
                                                    "City"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                        <input
                                            type="text"
                                            placeholder="State"
                                            value={invoiceData.to["State"]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    "to",
                                                    "State"
                                                )
                                            }
                                            className="invoicePartyDetailsHalfInput"
                                        />
                                    </div>
                                    <input
                                        placeholder="Country"
                                        type="text"
                                        value={invoiceData.to["Country"]}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                "to",
                                                "Country"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="invoiceItems">
                            <h3>Item details</h3>
                            <table>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Item Name</th>
                                    <th>Units</th>
                                    <th>Price</th>
                                    <th>Tax(%)</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                                {invoiceData.items?.map((item, key) => (
                                    <tr>
                                        <td>
                                            <p
                                                style={{
                                                    opacity: item.name
                                                        ? 1
                                                        : 0.4,
                                                }}
                                            >
                                                {key + 1}
                                            </p>
                                        </td>
                                        <td>
                                            <textarea
                                                type="text"
                                                className="invoiceItemsItemName"
                                                value={item.name}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        "items",
                                                        "name",
                                                        key
                                                    )
                                                }
                                                onInput={handleTextArea}
                                                id="autoResizeTextarea"
                                                rows="1"
                                                placeholder="item name/description"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min={1}
                                                value={item.quantity.toLocaleString()}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        "items",
                                                        "quantity",
                                                        key
                                                    )
                                                }
                                                placeholder="units"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min={1}
                                                value={item.price.toLocaleString()}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        "items",
                                                        "price",
                                                        key
                                                    )
                                                }
                                                placeholder="price"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min={1}
                                                value={item.tax.toLocaleString()}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        "items",
                                                        "tax",
                                                        key
                                                    )
                                                }
                                                placeholder="tax"
                                            />
                                        </td>
                                        <td>
                                            <p>
                                                {(
                                                    item.quantity * item.price +
                                                    (item.tax / 100) *
                                                        (item.quantity *
                                                            item.price)
                                                ).toLocaleString()}
                                            </p>
                                        </td>

                                        <td>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                className="invoiceCancelIcon"
                                                onClick={() =>
                                                    handleRemoveItem(key)
                                                }
                                            >
                                                <g clip-path="url(#clip0_1252_14508)">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        rx="8"
                                                        fill="#F5F9FF"
                                                    />
                                                    <path
                                                        d="M8.01255 8.92499L5.62505 11.325C5.49172 11.4583 5.33338 11.5229 5.15005 11.5187C4.96672 11.5146 4.80838 11.4458 4.67505 11.3125C4.55005 11.1792 4.48963 11.025 4.4938 10.85C4.49797 10.675 4.56255 10.5208 4.68755 10.3875L7.06255 7.99999L4.67505 5.58749C4.55005 5.45416 4.48755 5.29999 4.48755 5.12499C4.48755 4.94999 4.55005 4.79583 4.67505 4.66249C4.80838 4.52916 4.96672 4.46041 5.15005 4.45624C5.33338 4.45208 5.49172 4.51666 5.62505 4.64999L8.01255 7.04999L10.375 4.64999C10.5084 4.51666 10.6667 4.45208 10.85 4.45624C11.0334 4.46041 11.1917 4.52916 11.325 4.66249C11.45 4.79583 11.5105 4.94999 11.5063 5.12499C11.5021 5.29999 11.4375 5.45416 11.3125 5.58749L8.93755 7.99999L11.3125 10.3875C11.4375 10.5125 11.5021 10.6646 11.5063 10.8437C11.5105 11.0229 11.45 11.1792 11.325 11.3125C11.1917 11.4458 11.0334 11.5146 10.85 11.5187C10.6667 11.5229 10.5084 11.4583 10.375 11.325L8.01255 8.92499Z"
                                                        fill="#888888"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1252_14508">
                                                        <rect
                                                            width="16"
                                                            height="16"
                                                            fill="white"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td colSpan={6}>
                                        <button
                                            className="invoiceItemsAddButton"
                                            onClick={addItem}
                                        >
                                            <FaPlus /> Add new line item
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="invoiceCalculations">
                            <div className="invoiceNote">
                                <h3>Notes</h3>
                                <textarea
                                    className="invoiceNoteText"
                                    placeholder="Enter any note to remember or special text"
                                    value={invoiceData.notes}
                                    onChange={(e) =>
                                        handleInputChange(e, "", "notes")
                                    }
                                ></textarea>
                            </div>

                            <div className="invoiceAmounts">
                                <p>Sub Total </p>
                                <div>
                                    {invoiceData.items
                                        .reduce(
                                            (acc, val) =>
                                                acc +
                                                Number(
                                                    val.price * val.quantity
                                                ),
                                            0
                                        )
                                        .toLocaleString()}
                                </div>
                                <p>
                                    Discount
                                    <input
                                        type="number"
                                        min={0}
                                        className="invoiceDiscount"
                                        placeholder="00%"
                                        value={invoiceData.discount}
                                        onChange={(e) =>
                                            handleInputChange(e, "", "discount")
                                        }
                                    ></input>
                                </p>
                                <div className="invoiceDiscountValue">
                                    {(
                                        (invoiceData.discount / 100) *
                                        invoiceData.items.reduce(
                                            (acc, val) =>
                                                acc +
                                                Number(
                                                    val.price * val.quantity
                                                ),
                                            0
                                        )
                                    ).toLocaleString()}
                                </div>
                                <p>Tax Amount </p>
                                <div>
                                    {invoiceData.items
                                        .reduce(
                                            (acc, val) =>
                                                acc +
                                                (val.tax / 100) *
                                                    (val.price * val.quantity -
                                                        (invoiceData.discount /
                                                            100) *
                                                            val.price *
                                                            val.quantity),
                                            0
                                        )
                                        .toLocaleString()}
                                </div>

                                <p>Total Payable </p>
                                <div>
                                    {currency[invoiceData.currency]}
                                    {invoiceData.items
                                        .reduce(
                                            (acc, item) =>
                                                acc +
                                                (item.quantity * item.price +
                                                    (item.tax / 100) *
                                                        (item.quantity *
                                                            item.price -
                                                            (invoiceData.discount /
                                                                100) *
                                                                (item.quantity *
                                                                    item.price)) -
                                                    (invoiceData.discount /
                                                        100) *
                                                        (item.quantity *
                                                            item.price)),
                                            0
                                        )
                                        .toLocaleString()}
                                </div>
                            </div>
                        </div>
                        <div className="invoiceFooter">
                            <button
                                className="invoiceFooterSecondaryCTA"
                                onClick={handleSave}
                            >
                                Download
                            </button>
                            <button
                                className="invoiceFooterCTA"
                                onClick={handleSubmit}
                            >
                                Save & Continue
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InvoiceTemplate;
