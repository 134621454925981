import React, { useEffect, useState } from "react";
import "./authNavbar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAuth,
  updateUserDetails,
} from "../../redux/feature/user";
import { useNavigate } from "react-router-dom";
import iconUser from "../../img/icon_user.svg";


const AuthNavbar = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/server/logout`, {
        credentials: "include",
      });
      dispatch(updateAuth(false));
      dispatch(updateUserDetails({}));
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      if (openProfile) {
        setOpenProfile(false);
      }
    };

    if (openProfile) {
      console.log("added");
      document.addEventListener("click", handleOutsideClick);
    } else {
      console.log("removed");
      document.removeEventListener("click", handleOutsideClick);
    }
  }, [openProfile]);

  return (
    <>
      <div className="authNavbar">
        <div className="authNavbarLinks">
          {/* <span style={{width: "170px"}}>{organisation.name || "Organisation X"}</span> */}
          <span
            onClick={() => {
              navigate("/app/dashboard");
            }}
          >
            Project Management
          </span>
          <span
            onClick={() => {
              navigate("/invoice/list");
            }}
          >
            Invoicing
          </span>
        </div>
        <div className="authNavbarProfile">
          <img src={userDetails.icon ?? iconUser} onClick={(e) => {setOpenProfile(!openProfile); e.stopPropagation()}} />
          {openProfile && (
            <div
              className="authNavbarProfileMenu"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="authNavbarProfileMenuHeading">Account</div>
              <div className="authNavbarProfileMenuUser">
                <img
                  src={userDetails.icon ?? iconUser}
                  className="authNavbarProfileMenuUserImage"
                />
                <div className="authNavbarProfileMenuUserDetails">
                  <span className="authNavbarProfileMenuUserName">
                    {userDetails.name}
                  </span>
                  {userDetails.email && (
                    <span className="authNavbarProfileMenuUserEmail">
                      {userDetails.email}
                    </span>
                  )}
                </div>
              </div>
              <hr className="authNavbarProfileMenuDivider" />
              <div className="authNavbarProfileMenuList">
                <span>
                  <a href="/profile">Personal Profile</a>
                </span>
                {/* <span>Settings</span>
                <span>Notifications</span> */}
                <span onClick={handleLogout}>Log out</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AuthNavbar;
