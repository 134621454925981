import React, { useState } from 'react'
import iconUser from "../../img/icon_user.svg";
import './onboarding.css'
import { postData } from '../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrganisation, updateWorkspace } from '../../redux/feature/user';

const onboardingContent = [
    {
        heading: "How are you planning to use our platform?",
        description: "We’ll use your answers to streamline your experience. <br /> You can always adjust these settings later.",
        input: [{
            id: "single",
            type: "radio",
            label: "By Myself",
            value: "single"
        },
        {
            id: "team",
            type: "radio",
            label: "With my team",
            value: "team"
        }
        ]
    }, {
        heading: "What would you like to call your organization?",
        description: "Your organization is where your workspace will be.<br/> This could be your name or the name of your business / <br /> company.",
        input: [
            {
                id: "org",
                type: "text",
                placeholder: "your business name",
                value: "your business name"
            }
        ]
    }, {
        heading: "What would you like to call your first workspace?",
        description: "Each workspace can manage its own content calendar, members and more.",
        input: [
            {
                id: "workspace",
                type: "text",
                placeholder: 'workspace name here',
                value: 'workspace name here'
            }
        ]
    }, {
        heading: "Create your first post here",
        description: "This is an individual post that can be posted right away or <br/> scheduled to be posted later.",
        input: [
            {
                id: "title",
                type: "text",
                placeholder: "Post title here"
            },
            {
                id: "postDesc",
                type: "text",
                placeholder: "Post description goes here"
            }
        ]
    }
]

function Onboarding({ setShowOnboarding }) {
    const [step, setStep] = useState(0);
    const userDetails = useSelector(state=>state.user.userDetails);
    const [formValues, setFormValues] = useState({
        0: { platformUsage: 'single' },
        1: { org: `${userDetails.name}'s Org` },
        2: { workspace: `${userDetails.name}'s Workspace` },
        3: { title: '', postDesc: '' },
    });

    const dispatch = useDispatch();

    const handleInputChange = (step, inputId, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [step]: {
                ...prevValues[step],
                [inputId]: value,
            },
        }));
    };

    const handleStepIncrement = async (skipPost=false) => {
        if (step + 1 === onboardingContent.length) {
            if(!skipPost){
                if(!formValues[3].title?.trim()){
                    alert("title is mandatory");
                    return;
                }
            }
            await postData("updateUser", { individual: formValues[0].platformUsage === "single" ? 1 : 0 });
            const organisation = await postData("create-organisation", {
                orgName: formValues[1].org,
            });
            dispatch(
                updateOrganisation({ id: organisation.orgs[0].id, name: organisation.orgs[0].name })
            );
            const workspaceData = await postData("create-workspace", {
                orgId: organisation.orgs[0].id,
                workspaceName: formValues[2].workspace,
            });
            dispatch(
                updateWorkspace({ id: workspaceData.id, name: workspaceData.name })
            );
            if (formValues[3].title && !skipPost) {
                await postData("create-post", {
                    title: formValues[3].title,
                    description: formValues[step].postDesc,
                    workspace_id: workspaceData.id
                });
            }
            localStorage.setItem("onboarding",1);
            setShowOnboarding(false);
            return;
        }
        switch(step){
            case 1:
                if(!formValues[1].org?.trim()){
                    alert("Organisation name is mandatory");
                }else setStep(step + 1);
                break;
            case 2:
                if (!formValues[2].workspace?.trim()) {
                    alert("Workspace name is mandatory");
                }else setStep(step + 1);
                break;
            default:
                setStep(step + 1);
        }
    }

    return (
        <div className='onboardingContainer'>
            <div className="authNavbar ">
                <img src={iconUser} />
            </div>
            <div className='onboardingLeft'>
                <div className='leftHeading'>Welcome onboard!</div>
                <p>Let’s get you started with your first organisation creation.<br />
                    Your work is organised within these Orgs to help you manage your work.</p>
            </div>
            <div className='onboardingRight'>
                <div className='questionContainer'>
                    <div>
                        <div className='questionTabs'>
                            {
                                onboardingContent.map((_, i) => (
                                    <div key={`tabs_${i}`} className={`questionTab ${step >= i ? 'activeQuestionTab' : ''}`}></div>
                                ))
                            }
                        </div>
                    </div>

                    {/* {step===0 && <div className='onboardingContent'>
                        <div className='leftHeading'>How are you planning to use our platform?</div>
                        <p className='onboardingDesc'>We’ll use your answers to streamline your experience. <br />
                            You can always adjust these settings later.</p>
                        <div className='onboardingInputs'>
                            <div className='onboardingInputActive onboardingInput'>
                                <input type="radio" />
                                <p>By Myself</p>
                            </div>

                            <div className='onboardingInput'>
                                <input type="radio" />
                                <p>With my team</p>
                            </div>
                        </div>
                    </div>} */}
                    {/* {step === 1 && <div className='onboardingContent'>
                        <div className='leftHeading'>What would you like to call your organization?</div>
                        <p className='onboardingDesc'>Your organization is where your workspace will be.<br/>
                            This could be your name or the name of your business / <br/> company.</p>
                        <div className='onboardingInputs'>
                            <div className='onboardingInput'>
                                <input type="text" placeholder='your business name' />
                            </div>
                        </div>
                    </div>} */}
                    {/* {step ===2 && <div className='onboardingContent'>
                        <div className='leftHeading'>What would you like to call your first workspace?</div>
                        <p className='onboardingDesc'>Each workspace can manage its own content calendar, members and more.</p>
                        <div className='onboardingInputs'>
                            <div className='onboardingInput'>
                                <input type="text" placeholder='workspace name here'/>
                            </div>
                        </div>
                    </div>} */}
                    <div className='onboardingContent'>
                        <div className='leftHeading'>{onboardingContent[step].heading}</div>
                        <p className='onboardingDesc' dangerouslySetInnerHTML={{ __html: onboardingContent[step].description }} />
                        <div className='onboardingInputs'>
                            {
                                onboardingContent[step].input?.map((input) => (
                                    <div key={input.id} className={`onboardingInput ${(input.type === "radio" && formValues[step]?.platformUsage === input.value) ? "onboardingInputActive" : ""}`}>
                                        <input
                                            id={input.id}
                                            name={input.type === "radio" ? "platformUsage" : ""}
                                            type={input.type}
                                            placeholder={input.type === "text" ? input.placeholder : ""}
                                            value={input.type === "text" ? formValues[step]?.[input.id] : input.value}
                                            checked={input.type === "radio" ? formValues[step]?.platformUsage === input.value : undefined}
                                            onChange={(e) => handleInputChange(
                                                step,
                                                input.type === "radio" ? "platformUsage" : input.id,
                                                e.target.value
                                            )}
                                        />
                                        {input.type === "radio" && <label htmlFor={input.id}>{input.label}</label>}
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="onboardingAllBtns">
                        {step === 3 && <p style={{ cursor: "pointer" }} onClick={() => handleStepIncrement(true)}>Skip this &gt;</p>}
                        <div className='onboardingBtns'>
                            <button className={`onboardingBack ${step === 0 ? 'disableBtn' : ''}`} onClick={() => setStep(step - 1)} disabled={step === 0}>Back</button>
                            <button className='onboardingNext' onClick={()=>handleStepIncrement(false)}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <button onClick={()=>setShowOnboarding(false)}>hello</button> */}
        </div>
    )
}

export default Onboarding
