import React, { useEffect, useState } from 'react'
import { updateOrganisation, updateWorkspace } from '../../redux/feature/user';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../utils/api';
import Loader from '../loader/Loader';
import Onboarding from './Onboarding';

function CheckOnboarding({children}) {
    const [organisation, setOrganisation] = useState(
        useSelector((state) => state.user.organisation)
    );
    const [isLoading,setIsLoading] = useState(true);
    const [showOnboarding,setShowOnboarding] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!organisation.name) {
            const getOrganisation = async () => {
                const response = await getData("organisations");
                if (!response.orgs?.length) {
                    setShowOnboarding(true);
                    dispatch(
                        updateOrganisation({
                            id: "",
                            name: "",
                        })
                    );
                    dispatch(
                        updateWorkspace({
                            id: "",
                            name: "",
                        })
                    );
                    setOrganisation({
                        id: "",
                        name: "",
                    });
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                    dispatch(
                        updateOrganisation({
                            id: response.orgs[0].id,
                            name: response.orgs[0].name,
                        })
                    );
                    dispatch(
                        updateWorkspace({
                            id: response.orgs[0].workspace_id,
                            name: response.orgs[0].workspace_name,
                        })
                    );
                    setOrganisation({
                        id: response.orgs[0].id,
                        name: response.orgs[0].name,
                    });
                }
            };
            getOrganisation();
        }else setIsLoading(false);
    }, []);
    
  return isLoading?<Loader/>:showOnboarding?<Onboarding setShowOnboarding={setShowOnboarding}/>: children;
}

export default CheckOnboarding
