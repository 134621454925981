import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

function FacebookCallback() {
  const userDetails = useSelector((state) => state.user.userDetails)
    useEffect(() => {
        const currentUrl = window.location.href;
        if (currentUrl.includes('#')) {
            const hashIndex = currentUrl.indexOf('#');
            const accessTokenData = currentUrl.substring(hashIndex + 1); // Get everything after the hash
          const redirectUrl = `${process.env.REACT_APP_BACKEND_URL}/server/facebook/callback?${accessTokenData}&userId=${userDetails.id}`;
            window.location.href = redirectUrl; // Redirect to the new URL
        }
    }, []);

  return (
    <div>
      
    </div>
  )
}

export default FacebookCallback
