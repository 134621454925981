import "./calendar.css";
import backgroundImage from "../../img/Teams.png";

const Teams = () => {
  return (
    <div className="container sidebarContainer">
      <div
        className="background"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="text-container">
        <h1 className="title">Teams</h1>
        <p className="description">
          Collaborate seamlessly with your team, track progress, <br /> and
          receive instant updates on shared tasks.
        </p>
      </div>
    </div>
  );
};

export default Teams;
