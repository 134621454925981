import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import {useRef} from "react";
import {IconButton } from "@mui/material";

export default function DateTimePickerValue({ date, setDate }) {
  return (
    <div className="datePicker">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer components={["DateTimePicker"]}>
          <DateTimePicker
            value={date ? moment(date) : null}
            onChange={(newValue) => setDate(newValue)}
            format="DD/MM/YYYY hh:mm a"
            disablePast
            formatDensity="spacious"
            timeSteps={{minutes:15}}
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}


export function CustomDatePicker({ isOpen, setIsOpen, date, setDate, icon }) {
  const iconRef = useRef(null);
  const handleIconClick = () => {
      setIsOpen(!isOpen); // Open the DateTimePicker after a delay
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <IconButton ref={iconRef} onClick={handleIconClick}>
        <img src={icon} alt="Set reminder" title="Set reminder" />
      </IconButton>
        <DateTimePicker
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          value={date ? moment(date) : null}
          format="DD/MM/YYYY hh:mm a"
          onChange={(newValue) => {
            setDate(newValue.format("DD MMM YYYY hh:mm a"));
          }}
          PopperProps={{
            placement: "bottom-end", // Position of the popover
            anchorEl: iconRef.current,
          }}
          slots={{
            textField: ()=>null,
          }}
    
      />
    </LocalizationProvider>
  );
}
