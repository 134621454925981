import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userDetails: JSON.parse(localStorage.getItem("userDetails")) || {
        name: "",
        email: "",
    },
    auth: localStorage.getItem("auth") === "true",
    redirectUrl: localStorage.getItem("redirectUrl") || "",
    organisation: localStorage.getItem("organisation")?JSON.parse(localStorage.getItem("organisation")) : {
        id: "",
        name: "",
    },
    workspace: localStorage.getItem("organisation")?JSON.parse(localStorage.getItem("workspace")) : {
        id: "",
        name: "",
    },
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateUserDetails: (state, action) => {
            state.userDetails = action.payload;
            localStorage.setItem("userDetails", JSON.stringify(action.payload));
        },
        updateAuth: (state, action) => {
            state.auth = action.payload;
            if(action.payload==false){
                state.organisation = {
                    id: "",
                    name: "",
                };
                state.workspace = {
                    id: "",
                    name: "",
                }
                localStorage.setItem("organisation", "");
                localStorage.setItem("workspace", "");
            }
            localStorage.setItem("auth", action.payload.toString());
        },
        updateOrganisation: (state, action) => {
            state.organisation = action.payload;
            localStorage.setItem(
                "organisation",
                JSON.stringify(action.payload)
            );
        },
        updateWorkspace: (state, action) => {
            state.workspace = action.payload;   
            localStorage.setItem("workspace", JSON.stringify(action.payload));
        },
    },
});

export const {
    updateUserDetails,
    updateAuth,
    updateRedirectUrl,
    removeRedirectUrl,
    updateOrganisation,
    updateWorkspace,
} = userSlice.actions;
export default userSlice.reducer;
