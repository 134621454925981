import React, { useState } from "react";
import "./createWorkspace.css";
import { useSelector, useDispatch } from "react-redux";
import { updateWorkspace } from "../../redux/feature/user"; // Ensure this action is correctly imported
import { postData } from "../../utils/api";
import { toast } from "react-toastify";


const CreateWorkspace = ({ setShowPopup }) => {
  const [workspace, setWorkspace] = useState("Workspace_1");
  const organisation = useSelector((state) => state.user.organisation);
  const dispatch = useDispatch();

  const handelSave = async () => {
    if(!workspace.trim()){
      toast.error("Workspace name is required");
      return;
    }
    const workspaceData = await postData("create-workspace", {
      orgId: organisation.id,
      workspaceName: workspace,
    });

    dispatch(
      updateWorkspace({ id: workspaceData.id, name: workspaceData.name })
    );
    setShowPopup(false);
    toast.success("New workspace created!");

  };

  return (
    <>
      <div className="containerOverlay">
      </div>
      <div className="workspaceScreen">
        <p className="workspaceHead">Create New Workspace</p>
        <p>Each workspace can manage its own content calendar, members and more.</p>
        <input type="text" className="workspaceInput" value={workspace} onChange={(e)=>setWorkspace(e.target.value)} />
        <div className='workspaceBtns'>
          <button className='workspaceCancel' onClick={() => setShowPopup(false)}>Cancel</button>
          <button className='workspaceCreate' onClick={handelSave}>Create Workspace</button>
        </div>
      </div>
    </>
  );
};

export default CreateWorkspace;
