import React, { useEffect, useRef, useState } from "react";
import KanbanCard from "./KanbanCard";
import { FiPlus } from "react-icons/fi";
import "./dashboard.css";
import { postData } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { toast } from "react-toastify";
import { updateWorkspace } from "../../redux/feature/user";
import PostPopup from "../post/PostPopup";
import OnboardingPopup from "../onboarding/OnboardingPopup";

const statusList = {
  "todo": 0,
  "inprogress": 1,
  "scheduled": 2,
  "posted": 3
}

const platFormFilterList = [
  "Instagram",
  "Linkedin",
  "Youtube"
]

const Dashboard = () => {
  const [todoList, setTodoList] = useState([]);
  const [inprogressList, setInprogressList] = useState([]);
  const [scheduledList, setScheduledList] = useState([]);
  const [postedList, setPostedList] = useState([]);
  const workspace = useSelector(state => state.user.workspace);
  const organisation = useSelector(state => state.user.organisation);
  const [showWorkspaceDropdown, setShowWorkspaceDropdown] = useState(false);
  const [showFilterDropdown, setshowFilterDropdown] = useState(false);
  const [showLabelDropdown, setShowLabelDropdown] = useState(false);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [labelList, setLabelList] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const labelDropdownRef = useRef(null);
  const filterDropdownRef = useRef(null);
  const workspaceDropdownRef = useRef(null);
  const [showPostPopup, setShowPostPopup] = useState(false);
  const dispatch = useDispatch();
  const [showOnboardingPopup,setShowOnboardingPopup] = useState(false);

  const handleDragEnd = async (result) => {
    const { source, destination } = result;
    // If the card is dropped outside any droppable area
    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    // if (destination.droppableId === "posted") {
    //   toast.error("You can not change status to posted");
    //   return;
    // }
    let sourceList;
    let destinationList;

    // Identify source and destination lists
    switch (source.droppableId) {
      case 'todo':
        sourceList = todoList;
        break;
      case 'inprogress':
        sourceList = inprogressList;
        break;
      case 'scheduled':
        sourceList = scheduledList;
        break;
      case 'posted':
        sourceList = postedList;
        break;
      default:
        return;
    }

    switch (destination.droppableId) {
      case 'todo':
        destinationList = todoList;
        break;
      case 'inprogress':
        destinationList = inprogressList;
        break;
      case 'scheduled':
        destinationList = scheduledList;
        break;
      case 'posted':
        destinationList = postedList;
        break;
      default:
        return;
    }

    // if (destination.droppableId === "scheduled") {
    //   if (!sourceList[source.index].media?.length) {
    //     toast.error("No media Found!");
    //     return;
    //   } else if (!sourceList[source.index].scheduled_at) {
    //     toast.error("Scheduled date not found");
    //     return;
    //   }
    // }
    await postData(`updatePostStatus`, {
      postId: sourceList[source.index].id,
      postStatus: statusList[destination.droppableId]
    })
    // Moving the card
    const [movedCard] = sourceList.splice(source.index, 1);
    destinationList.splice(destination.index, 0, movedCard);
    // Update the state
    setTodoList([...todoList]);
    setInprogressList([...inprogressList]);
    setScheduledList([...scheduledList]);
    setPostedList([...postedList]);
    console.log("source: ", source, "destination: ", destination);
    toast.success(`Moved to ${destination.droppableId}`);
  };

  useEffect(() => {
    const getKanbanData = async () => {
      const response = await postData(`getKanbanData`, {
        workspaceId: workspace.id,
        labels: selectedLabels,
        platforms: selectedPlatforms
      });
      setTodoList(response.data.todo);
      setInprogressList(response.data.inprogress);
      setScheduledList(response.data.scheduled);
      setPostedList(response.data.posted);
    }
    getKanbanData();
  }, [workspace?.id, selectedLabels, selectedPlatforms])

  const handleWpDropdown = async () => {
    if (!workspaceList.length) {
      const data = await postData('workspaces', {
        orgId: organisation.id
      });
      setWorkspaceList(data.workspaces);
    }
    console.log(showWorkspaceDropdown)
    setShowWorkspaceDropdown(!showWorkspaceDropdown);
  }

  const changeWorkspace = (newWorkspace) => {
    dispatch(updateWorkspace({
      id: newWorkspace.id,
      name: newWorkspace.name
    }));
    setLabelList([])
    setShowWorkspaceDropdown(false);
  }

  const handleLabelDropdown = async () => {
    setShowLabelDropdown(!showLabelDropdown);
  }
  const filterByLabel = (label, e) => {
    if (e.target.checked) {
      setSelectedLabels([...selectedLabels, label])
    } else {
      setSelectedLabels(selectedLabels.filter((item) => item !== label));
    }
  }

  const filterByPlatforms = (platform, e) => {
    if (e.target.checked) {
      setSelectedPlatforms([...selectedPlatforms, platform])
    } else {
      setSelectedPlatforms(selectedPlatforms.filter((item) => item !== platform));
    }
  }
  const clearAllFilters = () => {
    setSelectedPlatforms([]);
    setSelectedLabels([]);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (labelDropdownRef.current && !labelDropdownRef.current.contains(event.target)) {
        setShowLabelDropdown(false);
      }
      if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
        setshowFilterDropdown(false);
      }
      if (workspaceDropdownRef.current && !workspaceDropdownRef.current.contains(event.target)) {
        setShowWorkspaceDropdown(false);
      }
    };
    if(localStorage.getItem("onboarding")){
      setShowOnboardingPopup(true);
      localStorage.removeItem("onboarding");
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getAllLabels = async () => {
      const data = await postData('getAllLabels', {
        workspaceId: workspace.id
      });
      setLabelList(data.labels);
    }
    getAllLabels();
  }, [workspace])

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {showPostPopup && <PostPopup setShowPostPopup={setShowPostPopup} />}
      {showOnboardingPopup && <OnboardingPopup setShowOnboardingPopup={setShowOnboardingPopup}/>}
      <div className="kanbanContainer sidebarContainer">
        <div className="kanbanView">
          <div className="emptyHead">
            <div className="currentWorkspace" ref={workspaceDropdownRef} onClick={(e) => { e.stopPropagation(); handleWpDropdown() }}>
              <p>{workspace.name}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M9.99984 12.9792C9.88873 12.9792 9.78456 12.9618 9.68734 12.9271C9.59012 12.8924 9.49984 12.8334 9.4165 12.75L5.58317 8.91671C5.43039 8.76393 5.354 8.56949 5.354 8.33337C5.354 8.09726 5.43039 7.90282 5.58317 7.75004C5.73595 7.59726 5.93039 7.52087 6.1665 7.52087C6.40262 7.52087 6.59706 7.59726 6.74984 7.75004L9.99984 11L13.2498 7.75004C13.4026 7.59726 13.5971 7.52087 13.8332 7.52087C14.0693 7.52087 14.2637 7.59726 14.4165 7.75004C14.5693 7.90282 14.6457 8.09726 14.6457 8.33337C14.6457 8.56949 14.5693 8.76393 14.4165 8.91671L10.5832 12.75C10.4998 12.8334 10.4096 12.8924 10.3123 12.9271C10.2151 12.9618 10.1109 12.9792 9.99984 12.9792Z" fill="#222222" />
              </svg>
              {showWorkspaceDropdown && <div className="wpDropdownContainer" style={{ left: "0px" }}>
                {
                  workspaceList.map((item, index) => (
                    <div key={index} className="wpdropdownListItem" onClick={(e) => e.stopPropagation()}>
                      <label key={item.id} onClick={() => { changeWorkspace(item) }}>{item.name}</label>
                    </div>
                  ))
                }
              </div>}
            </div>
            <div className="labelFilter" >
              <div onClick={clearAllFilters} className="labelSearch"><p>Clear all</p></div>
              <div ref={labelDropdownRef} className="labelSearch" onClick={(e) => { e.stopPropagation(); handleLabelDropdown() }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99805 4.54356C3.99805 4.24225 4.24231 3.99799 4.54363 3.99799H9.99941C10.1441 3.99799 10.2829 4.05547 10.3852 4.15778L15.4536 9.22621C16.1795 9.95211 16.1795 11.1377 15.4536 11.8636L11.8637 15.4536C11.1378 16.1795 9.95217 16.1795 9.22627 15.4536L4.15784 10.3851C4.05553 10.2828 3.99805 10.144 3.99805 9.99935V4.54356ZM5.0892 5.08914V9.77336L9.99783 14.682C10.2976 14.9818 10.7924 14.9818 11.0921 14.682L14.6821 11.0921C14.9818 10.7923 14.9818 10.2976 14.6821 9.99777L9.77342 5.08914H5.0892Z" fill="#222222" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.72607 7.27141C6.72607 6.97009 6.97034 6.72583 7.27165 6.72583H7.27711C7.57842 6.72583 7.82269 6.97009 7.82269 7.27141C7.82269 7.57272 7.57842 7.81699 7.27711 7.81699H7.27165C6.97034 7.81699 6.72607 7.57272 6.72607 7.27141Z" fill="#222222" />
                </svg>
                <p>Labels</p>
                {showLabelDropdown && <div className="wpDropdownContainer">
                  {
                    labelList.length ? labelList.map((item, index) => (
                      <div key={index} className="wpdropdownListItem" onClick={(e) => e.stopPropagation()}>
                        <input id={item.replace(" ", "")} type="checkbox" checked={selectedLabels.includes(item)} onChange={(e) => { filterByLabel(item, e) }} />
                        <label htmlFor={item.replace(" ", "")}>{item}</label>
                      </div>
                    )) :
                      <div className="wpdropdownListItem">
                        <label>No label present</label>
                      </div>
                  }
                </div>}
              </div>
              <div ref={filterDropdownRef} className="filterSearch" onClick={(e) => { e.stopPropagation(); console.log("object"); setshowFilterDropdown(!showFilterDropdown) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16 5.75C16 6.165 15.665 6.5 15.25 6.5H4.75C4.335 6.5 4 6.165 4 5.75C4 5.335 4.335 5 4.75 5H15.25C15.665 5 16 5.335 16 5.75ZM11.25 14H8.75C8.335 14 8 14.335 8 14.75C8 15.165 8.335 15.5 8.75 15.5H11.25C11.665 15.5 12 15.165 12 14.75C12 14.335 11.665 14 11.25 14ZM13.25 9.5H6.75C6.335 9.5 6 9.835 6 10.25C6 10.665 6.335 11 6.75 11H13.25C13.665 11 14 10.665 14 10.25C14 9.835 13.665 9.5 13.25 9.5Z" fill="#222222" stroke="#222222" stroke-width="0.2" />
                </svg>
                <p>Filters</p>
                {showFilterDropdown && <div className="wpDropdownContainer" style={{ right: "0px" }}>
                  {
                    platFormFilterList.map((item, index) => (
                      <div key={index} className="wpdropdownListItem" onClick={(e) => e.stopPropagation()}>
                        <input id={item} type="checkbox" checked={selectedPlatforms.includes(item)} onChange={(e) => { filterByPlatforms(item, e) }} />
                        <label htmlFor={item}>{item}</label>
                      </div>
                    ))
                  }
                </div>}
              </div>
            </div>
          </div>
          <div className="kanbanCards">
            <Droppable droppableId="todo" style={{ width: "100%" }}>
              {(provided, snapshot) => (
                <div className="kanbanCard" ref={provided.innerRef} {...provided.droppableProps}>
                  <div className="cardHead">
                    Idea/ to-do
                  </div>
                  <div className="cardBodyContainer" style={{maxHeight:"60vh"}}>
                    {todoList.length ? todoList.map((todo, index) => (
                      <Draggable key={todo.id} draggableId={todo.id.toString()} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <KanbanCard key={todo.id} data={todo} index={index} />
                          </div>
                        )}
                      </Draggable>
                    )) : <>No Ideas yet</>
                    }
                  </div>
                  <div className="addCtaDiv" onClick={() => setShowPostPopup(true)}>
                    <div className="addCardCta">
                      <FiPlus />
                      <p>Add Idea</p>
                    </div>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="inprogress">
              {(provided) => (
                <div className="kanbanCard" ref={provided.innerRef} {...provided.droppableProps}>
                  <div className="cardHead">
                    In Progress
                  </div>
                  <div className="cardBodyContainer">
                  {inprogressList.length ? inprogressList.map((inprogress, index) => (
                    <Draggable key={inprogress.id} draggableId={inprogress.id.toString()} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <KanbanCard key={inprogress.id} data={inprogress} index={index} />
                        </div>
                      )}
                    </Draggable>
                  )) : <>Nothing in progress</>}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId="scheduled">
              {(provided) => (
                <div className="kanbanCard" ref={provided.innerRef} {...provided.droppableProps}>
                  <div className="cardHead">
                    Scheduled
                  </div>
                  <div className="cardBodyContainer">
                  {scheduledList.length ? scheduledList.map((scheduled, index) => (
                    <Draggable key={scheduled.id} draggableId={scheduled.id.toString()} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <KanbanCard key={scheduled.id} data={scheduled} index={index} />
                        </div>
                      )}
                    </Draggable>
                  )) : <>Nothing scheduled yet</>}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId="posted">
              {(provided) => (
                <div className="kanbanCard" ref={provided.innerRef} {...provided.droppableProps}>
                  <div className="cardHead">
                    Posted
                  </div>
                  <div className="cardBodyContainer">
                    {postedList.length ? postedList.map((posted, index) => (
                      <Draggable key={posted.id} draggableId={posted.id.toString()} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <KanbanCard key={posted.id} data={posted} index={index} />
                          </div>
                        )}
                      </Draggable>
                    )) : <>Nothing Posted yet</>}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default Dashboard;
