import React from 'react'
import "./profile.css"
import profilePage from "../../img/profile_page.jpg"

const Profile = () => {
  return (
    <>
    <div className='profilebg'>
    </div>
    <div className='profileSoon'>
        <p>Connect your social media accounts, receive notifications for published content, and manage account settings</p>
    </div>
    </>
  )
}

export default Profile