import React from "react";
import img404 from "../../img/img_404.png";
import "./Custom404.css";

const Custom404 = () => {
    return (
        <div className="page404">
            <img src={img404} className="errorImage" alt="" />
            <div className="page404Text">
                <h1>Page not Found</h1>
                <h2>
                    The page you are looking for doesn’t exist. <br />
                    Please go back, or contact the support team.
                </h2>
            </div>
        </div>
    );
};

export default Custom404;
