import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateWorkspace } from "../../redux/feature/user";
import { postData } from "../../utils/api";
import PostPopup from "../post/PostPopup";
import CreateWorkspace from "../workspace/CreateWorkspace";
import { LuCalendarHeart } from "react-icons/lu";
import { TbLayoutDashboard } from "react-icons/tb";
import { MdOutlineWorkspaces } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { GiLinkedRings } from "react-icons/gi";
import { CgFileAdd } from "react-icons/cg";

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPostPopup, setShowPostPopup] = useState(false);
    const [showWorkspaces, setShowWorkspaces] = useState(false);
    const [activeWorkspace, setWorkspace] = useState(
        useSelector((state) => state.user.workspace)
    );
    const [showPopup, setShowPopup] = useState(false);
    const organisation = useSelector((state) => state.user.organisation);
    const [workspaces, setWorkspaces] = useState([]);
    const [url, setUrl] = useState(window.location.pathname);

    useEffect(() => {
        const sidebar = document.getElementsByClassName("sidebar")[0];
        if (sidebar.classList.contains("active")) {
            document.getElementsByClassName("sidebarContainer")[0].style.marginLeft = "98px";
        } else {
            document.getElementsByClassName("sidebarContainer")[0].style.marginLeft = "221px";
        }
        setUrl(window.location.pathname);
    }, [window.location.pathname]);

    useEffect(() => {
        if (organisation.id) {
            const getWorkspaces = async () => {
                const response = await postData("workspaces", {
                    orgId: organisation.id,
                });
                setWorkspaces(response.workspaces);
                if (!activeWorkspace.id && response.workspaces.length !== 0) {
                    dispatch(
                        updateWorkspace({
                            id: response.workspaces[0].id,
                            name: response.workspaces[0].name,
                        })
                    );
                    setWorkspace({
                        id: response.workspaces[0].id,
                        name: response.workspaces[0].name,
                    });
                }
            };
            if (!showPopup) getWorkspaces();
        }
    }, [showPopup]);

    const toggleWorkspaces = () => {
        const sidebar = document.getElementsByClassName("sidebar")[0];

        if (!showWorkspaces && sidebar.classList.contains("active")) {
            handleToggleSidebar();
        }
        setShowWorkspaces((prev) => !prev);
    };

    const handleToggleSidebar = () => {
        const sidebar = document.getElementsByClassName("sidebar")[0];

        if (sidebar.classList.contains("active")) {
            sidebar.classList.remove("active");
            document.getElementsByClassName("sidebarContainer")[0].style.marginLeft = "221px";
        } else {
            document.getElementsByClassName("sidebarContainer")[0].style.marginLeft = "98px";
            sidebar.classList.add("active");
            if(showWorkspaces){
                setShowWorkspaces(false);
            }
        }
    };

    return (
        <>
            {showPostPopup && <PostPopup setShowPostPopup={setShowPostPopup} />}
            {showPopup && <CreateWorkspace setShowPopup={setShowPopup} />}

            <ul className="sidebar">
                <li className="sidebarHeader">
                    <p>{organisation.name}</p>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        onClick={handleToggleSidebar}
                        className="sidebarCloseIcon"
                    >
                        <rect width="20" height="20" rx="10" fill="#F2F2F2" />
                        <path
                            d="M7.12556 10C7.12556 9.88042 7.14424 9.76831 7.18161 9.66368C7.21898 9.55904 7.28251 9.46188 7.3722 9.3722L11.4978 5.24664C11.6622 5.08221 11.8714 5 12.1256 5C12.3797 5 12.5889 5.08221 12.7534 5.24664C12.9178 5.41106 13 5.62033 13 5.87444C13 6.12855 12.9178 6.33782 12.7534 6.50224L9.25561 10L12.7534 13.4978C12.9178 13.6622 13 13.8714 13 14.1256C13 14.3797 12.9178 14.5889 12.7534 14.7534C12.5889 14.9178 12.3797 15 12.1256 15C11.8714 15 11.6622 14.9178 11.4978 14.7534L7.3722 10.6278C7.28251 10.5381 7.21898 10.441 7.18161 10.3363C7.14424 10.2317 7.12556 10.1196 7.12556 10Z"
                            fill="#222222"
                        />
                    </svg>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        onClick={handleToggleSidebar}
                        className="sidebarOpenIcon"
                    >
                        <rect width="20" height="20" rx="10" fill="#F2F2F2" />
                        <path
                            d="M12.9999 10C12.9999 9.88042 12.9812 9.76831 12.9439 9.66368C12.9065 9.55904 12.843 9.46188 12.7533 9.3722L8.62773 5.24664C8.46331 5.08221 8.25404 5 7.99993 5C7.74582 5 7.53655 5.08221 7.37213 5.24664C7.2077 5.41106 7.12549 5.62033 7.12549 5.87444C7.12549 6.12855 7.2077 6.33782 7.37213 6.50224L10.8699 10L7.37213 13.4978C7.2077 13.6622 7.12549 13.8714 7.12549 14.1256C7.12549 14.3797 7.2077 14.5889 7.37213 14.7534C7.53655 14.9178 7.74582 15 7.99993 15C8.25404 15 8.46331 14.9178 8.62773 14.7534L12.7533 10.6278C12.843 10.5381 12.9065 10.441 12.9439 10.3363C12.9812 10.2317 12.9999 10.1196 12.9999 10Z"
                            fill="#222222"
                        />
                    </svg>
                </li>
                <li className="" onClick={toggleWorkspaces}>
                    <div className="sidebarItem">
                        <MdOutlineWorkspaces className="sidebarIcon" />
                        <p>Workspaces</p>
                    </div>
                    {showWorkspaces && (
                        <ul className={`workspaceList`} onClick={(e)=>e.stopPropagation()}>
                            {workspaces.map((workspace) => (
                                <li
                                    key={workspace.id}
                                    className={`sidebarSubItem ${
                                        activeWorkspace.id === workspace.id &&
                                        "sidebarActiveSubItem"
                                    }`}
                                    onClick={() => {
                                        dispatch(
                                            updateWorkspace({
                                                id: workspace.id,
                                                name: workspace.name,
                                            })
                                        );
                                        setWorkspace({
                                            id: workspace.id,
                                            name: workspace.name,
                                        });
                                    }}
                                >
                                    {workspace.name}
                                </li>
                            ))}
                            <li
                                className="sidebarSubItem"
                                onClick={() => {
                                    setShowPopup(true);
                                }}
                            >
                                Create Workspace
                            </li>
                        </ul>
                    )}
                </li>
                <li
                    className={`sidebarItem ${
                        url === "/app/dashboard" && "sidebarActiveItem"
                    }`}
                    onClick={() => {
                        navigate("/app/dashboard");
                    }}
                >
                    <TbLayoutDashboard className="sidebarIcon" />
                    <p>Dashboard</p>
                </li>
                <li
                    className={`sidebarItem ${
                        url === "/platforms" && "sidebarActiveItem"
                    }`}
                    onClick={() => {
                        navigate("/platforms");
                    }}
                >
                    <GiLinkedRings className="sidebarIcon" />
                    <p>Platforms</p>
                </li>
                <li
                    className={`sidebarItem ${
                        url === "/app/calendar" && "sidebarActiveItem"
                    }`}
                    onClick={() => {
                        navigate("/app/calendar");
                    }}
                >
                    <LuCalendarHeart className="sidebarIcon" />
                    <p>Calendar</p>
                </li>
                <li
                    className={`sidebarItem ${
                        url === "/app/teams" && "sidebarActiveItem"
                    }`}
                    onClick={() => {
                        navigate("/app/teams");
                    }}
                >
                    <RiTeamLine className="sidebarIcon" />
                    <p>Teams</p>
                </li>

                <li>
                    <hr style={{ height: "1px solid #ccc" }} />
                </li>

                <li>
                    <button
                        className="ideaTasksListStatus"
                        onClick={() => setShowPostPopup(true)}
                    >
                        <CgFileAdd className="sidebarIcon" />
                        <p> Create Post</p>
                    </button>
                </li>
            </ul>
        </>
    );
};

export default Sidebar;
