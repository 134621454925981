import React from "react";
import "./ErrorPage.css";
import imgError from "../../img/img_error.png";

export const ErrorPage = () => {
    return (
        <div className="errorPage">
            <img src={imgError} alt="" srcset="" />
            <div className="errorPageText">
                <h1>Some random error occurred!</h1>
                <h2>
                    There's an error with the webpage. <br />
                    Please refresh the page, or contact support team.
                </h2>
            </div>
        </div>
    );
};
