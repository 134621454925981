import { Link, useNavigate } from 'react-router-dom';
import figmaIcon from "../../img/figma-1 1.svg";
import desktopSvg from "../../img/Desktop.svg";
import feature1 from '../../img/feature1.svg'
import feature2 from '../../img/feature2.svg'
import feature3 from '../../img/feature3.svg'
import feature4 from '../../img/feature4.svg'
import mobApp from '../../img/MobApp.svg'
import newfeature1 from '../../img/newfeat1.svg'
import newfeature2 from '../../img/newfeature2.svg'
import newfeature3 from '../../img/newfeat3.svg'
import newfeature4 from '../../img/newfeat4.svg'
import newfeature5 from '../../img/newfeat5.svg'
import Slider from "react-slick";
import test from '../../img/test.svg'
import videoThumb from '../../img/iframe.svg'
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import appleStore from "../../img/playstore.svg"
// import leftEllipse from "../../img/Ellipses-left.svg"
// import rightEllipse from "../../img/right-ellipse.svg"
// import rectangle from "../../img/rectangle.svg"
// import teamEllipse from "../../img/team-ellipses.svg"
// import iphones from "../../img/Iphones.svg"
import './home.css'
import { useSelector } from 'react-redux';


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Home() {
  const auth = useSelector((state)=>state.user.auth);
  const navigate = useNavigate();
  return (
    <>
      {/* header */}
      <nav>
        <div className='header-left'>
          <ul>
            <li><Link to="/"><img src={figmaIcon} alt="backdoor-logo" /></Link></li>
            <li>Products</li>
            <li>Solutions</li>
            <li>Pricing</li>
            <li>Resources</li>
          </ul>
        </div>
        <div className='header-right'>
          <ul>
            {!auth && <li className='login-cta' onClick={() =>navigate('/login')
            }>Log In</li>}
            <li><button onClick={() => navigate(auth?'/app/dashboard':'/login')} >Start For Free</button></li>
          </ul>
        </div>
      </nav>

      {/* HERO */}
      <section className='hero-section'>
        <h1 className='main-heading'>All-in-One Content Collaboration Suite!</h1>
        <p className='main-desc'>Streamline your creative processes with our integrated tools designed for content creators, managers, and teams.</p>
        <button className='hero-cta'>Start Free Trial</button>
        <div>
          <img className='desktop-img' src={desktopSvg} alt="" />
        </div>
        {/* <img className='left-ellipse' src={leftEllipse} alt="" />
        <img className='right-ellipse' src={rightEllipse} alt="" /> */}
      </section>
      {/* <section className='clients-section'>
        <h2 className='client-heading'>Our Clients</h2>
        <div className='clients-logo'>

        </div>
      </section> */}

      <section className='feature-section'>
        <div className='feature-left'>
          <div>
            <p className='sys-feat'>System features</p>
            <h2 className='feat-head'>Powerful features</h2>
            <p className='feat-desc'>Pretium lectus ultrices sit tempor, sit ullamcorper volutpat et et. Auctor turpis semper id sit ornare maecenas lectus sed.</p>
          </div>
          <div className='features'>
            <div className='feature-box'>
              <img src={feature1} alt="" />
              <h3>Content Planning and Scheduling</h3>
              <p>Plan, create, and schedule your posts across multiple platforms.<br/>
                Automate your content delivery and ensure you never miss a beat.</p>
            </div>
            <div className='feature-box'>
              <img src={feature2} alt="" />
              <h3>Project Management Tools</h3>
              <p>Utilize Kanban boards for real-time project tracking.<br/>
                Manage all your creative projects from one dashboard.</p>
            </div>
            <div className='feature-box'>
              <img src={feature3} alt="" />
              <h3>Collaboration and Feedback</h3>
              <p>Invite team members and clients to collaborate.<br/>
                Gather feedback without requiring external logins, enhancing workflow efficiency.
</p>
            </div>
          </div>

        </div>
        <div className='feature-right'>
          <img className='mob-app' src={mobApp} alt="" />
          {/* <img className='rectangle' src={rectangle} alt="" /> */}
        </div>
      </section>

      <section className='newfeature-section'>
        <div className='row'>
          <div className='img50'>
            <img src={newfeature1} alt="" height={500} />
          </div>
          <div className='img50 new-feat'>
            <h2>Try new features of our tool</h2>
            <p>Scelerisque auctor dolor diam tortor, fames faucibus non interdum nunc. Ultrices nibh sapien elit gravida ac, rutrum molestie adipiscing lacinia.</p>
            <button className='hero-cta'>Start For Free</button>
          </div>
        </div>
        <div className="row">
          <div className='img40'>
            <img src={newfeature2} alt="" />
          </div>
          <div className='img10'>
            <img src={newfeature3} alt="" />
          </div>
          <div className='img40'>
            <img src={newfeature4} alt="" />
          </div>
          <div className='img10'>
            <img src={newfeature5} alt="" />
          </div>
        </div>
      </section>

      <section className='testimonial-section'>
        <h2>Hear From Our Users</h2>
        <p className='test-desc'>Discover why top content creators and managers trust Kwikzy to enhance their operational efficiency and creativity.</p>
        <div>
          <Slider {...settings}>
            <div className='test-card'>
              <img src={test} alt="" />
              <div className='test-content'>
                <p className='test-name'>Name, Designation</p>
                <p className='test-about'>Enim sagittis, sit porttitor morbi lobortis amet, libero adipiscing auctor. Malesuada tristique libero, id netus tincidunt. Egestas ac arcu amet nisl quis est ...</p>
              </div>
            </div>
            <div className='test-card'>
              <img src={test} alt="" />
              <div className='test-content'>
                <p className='test-name'>Name, Designation</p>
                <p className='test-about'>Enim sagittis, sit porttitor morbi lobortis amet, libero adipiscing auctor. Malesuada tristique libero, id netus tincidunt. Egestas ac arcu amet nisl quis est ...</p>
              </div>
            </div>
          </Slider>
        </div>
      </section>

      <section className='grow-business'>
        <div className='business-head'>
          <h2>Get your business to grow fast</h2>
          <p>Sed ut vestibulum eget purus ornare. Risus elit et fringilla habitant ut facilisi.</p>
          <button>Watch demo</button>
        </div>
        <img src={videoThumb} alt="" />
      </section>

      <section className='manage-team'>
        <p className='caption'>START BUILDING IDEAS</p>
        <h2>Manage your team now!</h2>
        <p>Malesuada ut aliquam at ac est nisi, interdum etiam dignissim.
          Risus elit et fringilla habitant ut facilisi.</p>
          <button>
            Start For Free
          </button>
          {/* <img className='team-ellipse' src={teamEllipse} alt="" /> */}
        {/* <img className='iphones' src={iphones} alt="" /> */}
      </section>
      <footer>
        <div className='footer-column'>
          <ul>
            <li>CATEGORIES</li>
            <li>User Interface</li>
            <li>Digital Media</li>
            <li>User Experience</li>
            <li>Lifestyle</li>
            <li>Programming</li>
            <li>Animation</li>
          </ul>
          <ul>
            <li>CATEGORIES</li>
            <li>User Interface</li>
            <li>Digital Media</li>
            <li>User Experience</li>
            <li>Lifestyle</li>
            <li>Programming</li>
            <li>Animation</li>
          </ul>
          <ul>
            <li>CATEGORIES</li>
            <li>User Interface</li>
            <li>Digital Media</li>
            <li>User Experience</li>
            <li>Lifestyle</li>
            <li>Programming</li>
            <li>Animation</li>
          </ul>
          <ul>
            <li>CATEGORIES</li>
            <li>User Interface</li>
            <li>Digital Media</li>
            <li>User Experience</li>
            <li>Lifestyle</li>
            <li>Programming</li>
            <li>Animation</li>
          </ul>
          <ul>
            <li>CATEGORIES</li>
            <li>User Interface</li>
            <li>Digital Media</li>
            <li>User Experience</li>
            <li>Lifestyle</li>
            <li>Programming</li>
            <li>Animation</li>
          </ul>
        </div>
        <div className="footer-row">
          <p style={{width:"55%"}}>@ 2023 Wrapp. All rights reserved</p>
          <div className='foot-links'>
            <span>Terms</span>
            <span>Privacy</span>
            <span>Contact</span>
          </div>
          <div className='social-icons'>
            <FaYoutube/>
            <FaFacebookF/>
            <FaXTwitter/>
            <FaInstagram/>
            <FaLinkedinIn/>
          </div>
          <img src={appleStore} alt="" style={{width:"auto"}}/>
        </div>
      </footer>
    </>
  );
}

export default Home;
