import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MdOutlineCreateNewFolder } from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

const InvoiceSidebar = () => {
    const navigate = useNavigate();
    const [url, setUrl] = useState(window.location.pathname);

    useEffect(() => {
        setUrl(window.location.pathname);
    }, [window.location.pathname]);

    return (
        <>
            <ul className="invoiceSidebar">
                <li
                    className={`invoiceSidebarItem ${
                        url === "/invoice/list" && "invoiceSidebarActiveItem"
                    }`}
                    onClick={() => {
                        navigate("/invoice/list");
                    }}
                >
                    <LiaFileInvoiceDollarSolid className="invoiceSidebarIcon" />
                    <p>My Invoices</p>
                </li>

                <li
                    className={`invoiceSidebarItem ${
                        url === "/invoice/template" &&
                        "invoiceSidebarActiveItem"
                    }`}
                    onClick={() => {
                        navigate("/invoice/template");
                    }}
                >
                    <MdOutlineCreateNewFolder className="invoiceSidebarIcon" />
                    <p>Create Invoice</p>
                </li>
            </ul>
        </>
    );
};

export default InvoiceSidebar;
