import React, { useEffect, useState } from "react";
import iconInsta from "../../img/icon_insta.svg";
import iconYT from "../../img/icon_yt.svg";
import iconLI from "../../img/icon_linkedin.svg";
import { toast } from "react-toastify";
import { LuMoreVertical, LuPencil } from "react-icons/lu";
import { FaRegTrashCan } from "react-icons/fa6";

import "./platforms.css";
import { getData, postData } from "../../utils/api";

const platforms = {
  Instagram: {
    name: "Instagram",
    icon: iconInsta,
    url: `https://www.facebook.com/v20.0/dialog/oauth?client_id=${process.env.REACT_APP_FB_APP_ID}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${process.env.REACT_APP_APP_URL}/facebook/callback/&response_type=token&scope=instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement`,
  },
  Youtube: {
    name: "Youtube",
    icon: iconYT,
    url: `${process.env.REACT_APP_BACKEND_URL}/server/youtube-login`,
    comparer: "yt_token",
  },
  Linkedin: {
    name: "Linkedin",
    icon: iconLI,
    url: `${process.env.REACT_APP_BACKEND_URL}/server/linkedin/auth`,
    comparer: "linkedin_token",
  },
};

function Platforms() {
  const [savedPlatforms, setSavedPlatforms] = useState([]);
  const [showEditbox, setShowEditbox] = useState(null);

  const handleAddPlatform = async (platform) => {
    let flag=false;
    savedPlatforms.forEach((item)=>{
      if(item.platform_name===platform){
        toast.warn(`${platform} account already added`);
        flag=true;
        return;
      }
    })
    if(!flag)window.open(platforms[platform]["url"], "_self");
  };

  const fetchSavedPlatforms = async () => {
    const response = await getData("getSavedPlatforms");

    if (response.error) {
      toast.error(response.error);
      return;
    }
    console.log(response.data);
    setSavedPlatforms(response.data);
  };

  const handleShowEditbox = (e, index = null) => {
    e.stopPropagation();
    setShowEditbox(index ?? null);
  };

  const handleConnection = async (e, platform, action) => {
    e.stopPropagation();
    if(action==="remove" && window.confirm("Do you really want to remove connection")){
      const response = await postData("removeLinkedPlatform", {
        platform: platform
      });
      if(response.status===200){
        toast.success(response.message);
        setSavedPlatforms(savedPlatforms.filter((item)=>item.platform!==platform));
      } else toast.error(response.error);
    }
    setShowEditbox(null);
  };

  useEffect(() => {
    fetchSavedPlatforms();
  }, []);
  return (
    <section
      className="platformAuthContainer sidebarContainer"
      onClick={(e) => handleShowEditbox(e)}
    >
      <div className="platformAuth">
        <p className="platformHead">Platform Authentication</p>
        <div className="allPlatforms">
          <div>
            <p className="platformsP1">
              You have {savedPlatforms.length ? savedPlatforms.length : "no"}{" "}
              Active connections!
            </p>
            <p className="platformsP2">Select platform to add Connection</p>
          </div>
          <div className="platformIconsDiv">
            {Object.entries(platforms).map((platform, index) => (
              <img
                key={index}
                src={platform[1].icon}
                alt={platform.name}
                onClick={() => handleAddPlatform(platform[0])}
              />
            ))}
          </div>
        </div>
      </div>
      {!!savedPlatforms.length && (
        <div className="linkedPlatformsContainer">
          <p className="platformHead">Linked Platforms</p>
          <div className="allLinkedPlatforms">
            {savedPlatforms.map((plat, index) => (
              <div key={index} className="linkedCard">
                <div>
                  <img src={platforms[plat.platform]?.icon} alt="" />
                </div>
                <div className="platformDetail">
                  <p>{plat.platform}</p>
                  <p>{plat.profile_name}</p>
                </div>
                <LuMoreVertical
                  className="openEditbox"
                  onClick={(e) => handleShowEditbox(e, index)}
                />
                {showEditbox === index && (
                  <div
                    className="platformEditbox"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* <div
                      className="editCol"
                      onClick={(e) => handleConnection(e, plat, "edit")}
                    >
                      <LuPencil />
                      <p>Edit/Modify</p>
                    </div> */}
                    <div
                      className="editCol"
                      onClick={(e) => handleConnection(e, plat.platform, "remove")}
                    >
                      <FaRegTrashCan />
                      <p>Remove connection</p>
                    </div>
                  </div>
                )}
                <div className="platformStatus">Active</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default Platforms;
