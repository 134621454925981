import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const saveMultiPagePDF = async (input, invoiceNumber = "INVOICE") => {
    if (!input) return;

    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = input;
    tempContainer.style.position = "absolute";
    tempContainer.style.left = "-9999px";
    tempContainer.style.width = "100";
    tempContainer.style.maxWidth = "100%";
    tempContainer.style.padding = "0";
    tempContainer.style.marginTop = "500px";
    document.body.appendChild(tempContainer);

    await html2canvas(tempContainer, { scale: 3 }).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const imgHeight = (pdfWidth * canvasHeight) / canvasWidth;
        let heightLeft = imgHeight;

        let position = 0;

        const imgData = canvas.toDataURL("image/webp");

        pdf.setFillColor(255, 255, 255);
        pdf.addImage(imgData, "WEBP", 0, position, pdfWidth, imgHeight,'','FAST');
        pdf.rect(0, 0, pdfWidth, 5, "F");
        pdf.rect(0, pdfHeight - 10, pdfWidth, 10, "F");
        heightLeft = heightLeft - pdfHeight + 16;

        while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.setFillColor(255, 255, 255);

            pdf.addImage(imgData, "WEBP", 0, position, pdfWidth, imgHeight,'','FAST');
            pdf.rect(0, 0, pdfWidth, 5, "F");
            pdf.rect(0, pdfHeight - 10, pdfWidth, 10, "F");

            heightLeft = heightLeft - pdfHeight + 16;
        }

        pdf.save(invoiceNumber + ".pdf");
        document.body.removeChild(tempContainer);
    });
};
