import React, { useEffect, useRef, useState } from 'react'
import { LuMoreHorizontal, LuPencil } from 'react-icons/lu'
import videoIcon from "../../img/videoIcon.svg";
import fileIcon from "../../img/FileIcon.svg";
import imageIcon from "../../img/BsFillImageFill.svg"
// import { FaRegTrashCan } from 'react-icons/fa6';
import iconUser from "../../img/icon_user.svg";
import calendarIcon from "../../img/calendorIcon.svg";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const platformStyleConfig = {
    Instagram: {
        style: {
            color: "#9735c5",
            background: "rgba(151, 53, 197, 0.1)",
        },
    },
    Youtube: {
        style: {
            color: "#F00",
            background: "rgba(255, 0, 0, 0.10)",
        },
    },
    Linkedin: {
        style: {
            color: "#0A66C2",
            background: "rgba(10, 102, 194, 0.1)",
        }
    },
};

function KanbanCard({ data, index }) {
    console.log(data);
    const navigate = useNavigate();

    return (
        <div className="cardBody" onClick={() => navigate(`/app/post/${data.id}`)}>
            <div className="cardBodyTop">
                {data.imgUrl && <div className="postImg">
                    <img src={data.imgUrl} />
                </div>}
                <div className="cardTitleDesc">
                    <p className="cardPostTitle">{data.title}</p>
                    {data.platforms && <div className="addedPlatforms">
                        {data.platforms.split(",")[0] !== '' && data.platforms.split(",").map((platform, index) => (
                            <div key={index} className="platformChip" style={platformStyleConfig[platform].style}>
                                {platform}
                            </div>
                        ))}
                    </div>}
                </div>

            </div>
            <div className="cardAssigneeDiv">
                <div className="cardAssignee cardAssigneeImg">
                    <img src={data.assigneeImg??iconUser} alt="" />
                </div>
                {data.scheduled_at && <div className="cardAssignee">
                    <img src={calendarIcon} alt="" />
                    <span className='date'>{moment(data.scheduled_at).format("DD MMM ")}</span>
                </div>}
            </div>
        </div>
    )
}

export default KanbanCard;
