import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postData } from "../../utils/api";
import Google from "../../img/google.svg";
import { useDispatch } from "react-redux";
import { updateAuth, updateUserDetails } from "../../redux/feature/user";
import { LuEye, LuEyeOff } from "react-icons/lu";

function SignupComponent({ handleGoogleAuth, setShowLogin }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleEmailSignup = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("Password mismatch");
            return;
        }
        const response = await postData("emailSignup", { email, password });
        if (response.error) {
            toast.error(response.error);
        } else {
            toast.success(response.message);
            dispatch(updateAuth(true));
            dispatch(updateUserDetails(response.user));
            navigate('/app/dashboard');
        }
    };

    return (
        <form method="POST" onSubmit={handleEmailSignup}>
            <div className="authLeft">
                <div className="authPopup">
                    <div className="authPopupHeading">Sign up for free</div>
                    <div className="authSocial">
                        <div
                            className="authSocialBtns"
                            onClick={handleGoogleAuth}
                        >
                            <img src={Google} />
                            <span>Continue with Google</span>
                        </div>
                        <div className="emailSigninHead">
                            <div
                                className="line"
                                style={{ width: "78.5px" }}
                            ></div>
                            <span>or sign up with email</span>
                            <div
                                className="line"
                                style={{ width: "78.5px" }}
                            ></div>
                        </div>
                    </div>
                    <div className="authSocial">
                        <div className="authInput">
                            <p>Email Address</p>
                            <input
                                type="email"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="authInput">
                            <p>Password</p>
                            <div className="passwordInput">
                                <input
                                    type={passwordVisible?"text":"password"}
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    required
                                    style={{ width: "100%" }}
                                />
                                {passwordVisible ? (
                                    <LuEyeOff onClick={() => setPasswordVisible(false)} />
                                ) : (
                                    <LuEye onClick={() => setPasswordVisible(true)} />
                                )}
                            </div>
                        </div>
                        <div className="authInput">
                            <p>Confirm Password</p>
                            <div className="passwordInput">
                                <input
                                    type="password"
                                    placeholder="Re-enter your password"
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    required
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="authTerms">
                        <input type="checkbox" required />
                        <p>
                            I've read and agreed to{" "}
                            <Link>Terms & Conditions</Link>
                        </p>
                    </div>
                    <button className="authSignin" type="submit">
                        Sign Up
                    </button>
                    <p className="authNewText">
                        Already have an account?{" "}
                        <span onClick={() => setShowLogin(true)}> Sign in</span>
                    </p>
                </div>
            </div>
        </form>
    );
}

export default SignupComponent;
