const CustomOption = (props) => {
    return (
        <div className="custom-option" {...props.innerProps}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={props.data.icon} // Assuming your data has an 'icon' property
                    alt=""
                    style={{ width: 20, height: 20, marginRight: 10 }}
                />
                {props.data.label}
            </div>
        </div>
    );
};
export const CustomSingleValue = ({ data }) => {
    return (
        <div className="selectedCustomOption">
            <img
                src={data.icon} // Ensure your data has an 'icon' property
                alt=""
                style={{ width: 20, height: 20, marginRight: 10 }}
            />
            {data.label}
        </div>
    );
};

export default CustomOption;