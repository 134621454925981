import React from 'react'
import styles from './terms.module.css'

const Terms = () => {
    return (
        <div className={styles.termsContainer}>
            <h1>Terms of Use</h1>
            
            <ol>
                <li><h3> Introduction</h3>
                    <p>Welcome to ProjectX, a SaaS platform designed to streamline the creative workflow for content creators and their teams. These Terms and Conditions ("Terms") govern your use of our website, platform, and services ("Services"). By accessing or using ProjectX, you agree to be bound by these Terms. If you do not agree with these Terms, you must discontinue using our Services immediately.</p></li>
                <li><h3>Use of the Website and Services</h3>
                    <h4>Eligibility</h4>
                    <ol>
                        <li>You must be at least 16 years old to use our Services. By using ProjectX, you confirm that you meet this requirement.
                        </li>
                    </ol>
                    <h4>User Conduct</h4>
                    <p>You agree to use our Services only for lawful purposes and in accordance with these Terms. Prohibited activities include, but are not limited to:</p>
                    <ol>
                        <li>Engaging in fraudulent activities.</li>
                        <li>Distributing viruses or harmful software.</li>
                        <li>Violating any intellectual property rights.</li>
                        <li>Harassing or abusing other users.</li>
                    </ol></li>

                <li>
                    <h3> Accounts and Registration</h3>
                    <h4>Account Creation</h4>
                    <ol>
                        <li>To access certain features of our platform, you must create an account. You agree to provide accurate and complete information during registration and to keep this information up to date.</li>
                    </ol>

                    <h4>User Responsibilities</h4>
                    <ol>
                        <li>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                    </ol>

                    <h4>Termination of Accounts</h4>
                    <ol>
                        <li>We reserve the right to suspend or terminate your account at our discretion if you violate these Terms or engage in any activity that harms ProjectX or other users.</li>
                    </ol>
                </li>
                
                <li>
                    <h3>Intellectual Property</h3>
                    <h4>Ownership</h4>
                    <ol>
                        <li>All content, features, and functionality on ProjectX, including but not limited to text, graphics, logos, and software, are the exclusive property of ProjectX and its licensors. All rights are reserved.</li>
                    </ol>

                    <h4>Usage Rights</h4>
                    <ol>
                        <li>You are granted a limited, non-exclusive, non-transferable license to use our Services for your personal or business use, subject to these Terms.</li>
                    </ol>
                </li>

                <li>
                    <h3>User-Generated Content</h3>
                    <h4>Content Ownership</h4>
                    <ol>
                        <li>You retain ownership of any content you create and upload to ProjectX. However, by uploading content, you grant ProjectX a non-exclusive, royalty-free, worldwide license to use, display, reproduce, and distribute your content on our platform.</li>
                    </ol>

                    <h4>Moderation Rights</h4>
                    <ol>
                        <li>ProjectX reserves the right to review, edit, or remove any content that violates these Terms or is deemed inappropriate, without notice.</li>
                    </ol>
                </li>
                <li>
                    <h3>Payment and Subscription</h3>
                    <h4>Payment Terms</h4>
                    <ol>
                        <li>Some features of ProjectX require a paid subscription. By selecting a paid plan, you agree to pay all associated fees and charges.</li>
                    </ol>

                    <h4>Refund Policy</h4>
                    <ol>
                        <li>Refunds are only provided under specific circumstances as outlined in our Refund Policy. Please review this policy for more information.</li>
                    </ol>

                    <h4>Price Changes</h4>
                    <ol>
                        <li>ProjectX reserves the right to change subscription fees at any time. We will notify users of any price changes at least 30 days in advance.</li>
                    </ol>
                </li>
                <li>
                    <h3>Privacy Policy</h3>
                    <ol>
                        <li>We value your privacy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</li>
                    </ol>
                </li>

                <li>
                    <h3>Disclaimers</h3>
                    <h4>"As Is" Provision</h4>
                    <ol>
                        <li>ProjectX is provided on an "as is" and "as available" basis. We do not warrant that the Services will be uninterrupted or error-free.</li>
                    </ol>

                    <h4>Limitation of Liability</h4>
                    <ol>
                        <li>In no event shall ProjectX or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the Services.</li>
                    </ol>
                </li>

                <li>
                    <h3>Governing Law</h3>
                    <ol>
                        <li>These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under these Terms will be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].</li>
                    </ol>

                </li>
                <li>
                    <h3>Changes to Terms</h3>
                    <ol>
                        <li>ProjectX reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the Services after changes are posted constitutes your acceptance of the modified Terms.</li>
                    </ol>
                </li>
                <li>
                    <h3>Contact Information</h3>
                    <ol>
                        <li>If you have any questions or concerns about these Terms, please contact us at:
                            <ul>
                                <li><span>Email:</span> learnproduct3@gmail.com</li>
                                <li><span>Address:</span> A-281 Chattarpur, phase 2, Delhi</li>
                            </ul>
                        </li>
                    </ol>
                </li>
            </ol>   
        </div>
    )
}

export default Terms
