import React, { useState } from 'react'
import Slider from "react-slick";
import avatarMen from "../../img/avatar_men.jpeg"
import avatarWomen from "../../img/avatar_women.jpg"
import avatar2 from "../../img/agency_men.jpg"
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/api';
import { LuEyeOff, LuEye } from 'react-icons/lu';

const testimonials = [
    {
        review: "Kwikzy has transformed our workflow! It’s so intuitive that our entire team got up to speed in no time, making project collaboration a breeze.",
        name: " Saurabh",
        profession: "Content Creator",
        img: avatarMen
    },
    {
        review: "Kwikzy is a game-changer. It’s exactly what this industry has been missing!",
        name: "Anchal Sharma",
        profession: "Linkedin Top Voice",
        img: avatarWomen
    },
    {
        review: "Kwikzy interface is so clean and straightforward. It’s helped me reduce the time spent on admin work and focus more on creative aspects of my role.",
        name: "Ayush",
        profession: "Agency owner",
        img: avatar2
    },
];
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};

function ResetPassword() {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const { id } = useParams();
    const resetPassword = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }
        const response = await postData("resetPassword", { password, id });
        if (response.error) {
            toast.error(response.error);
        } else toast.success("Password reset successfully");
        navigate("/login");
    }
    return (
        <section className="authLogin">
            <form method="POST" onSubmit={resetPassword}>
                <div className="authLeft">
                    <div className="authPopup">
                        <div className="authPopupHeading">
                            Set a new Password
                        </div>
                        <div className="authSocial">
                            <div className="authInput">
                                <p>Enter your password</p>
                                <div className="passwordInput">
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder="Enter your password"
                                        style={{
                                            paddingRight: "40px",
                                            width: "100%",
                                        }}
                                        required
                                    />
                                    {passwordVisible ? (
                                        <LuEyeOff onClick={() => setPasswordVisible(false)} />
                                    ) : (
                                        <LuEye onClick={() => setPasswordVisible(true)} />
                                    )}
                                </div>
                            </div>
                            <div className="authInput">
                                <p>Re-enter your password</p>
                                <div className="passwordInput">
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        placeholder="Confirm your password"
                                        style={{
                                            paddingRight: "40px",
                                            width: "100%",
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <button className="authSignin" type="submit">
                            Reset Password
                        </button>
                        <p className="authNewText" style={{ cursor: "pointer" }} onClick={() => navigate("/login")}>
                            Back to login
                        </p>
                    </div>
                </div>
            </form>
            <div className="authRight">
                <div className="authTestimonial">
                    <Slider {...settings}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index}>
                                <p style={{ fontWeight: "500" }}>{testimonial.review}</p>
                                <div className="profileImg">
                                    <img src={testimonial.img} />
                                </div>
                                <div style={{ marginTop: "24px" }}>
                                    <p className="authTestimonialUser">
                                        {testimonial.name}
                                    </p>
                                    <p className="authTestimonialDesignation">
                                        {testimonial.profession}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword
