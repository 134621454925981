import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../../utils/api";
import { toast } from "react-toastify";
import Google from "../../img/google.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth, updateUserDetails } from "../../redux/feature/user";
import { LuEye, LuEyeOff } from "react-icons/lu";

function LoginComponent({ handleGoogleAuth, setShowLogin }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const auth = useSelector((state) => state.user.auth);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleEmailLogin = async (e) => {
        e.preventDefault();
        const response = await postData("emailLogin", { email, password });

        if (response.error) {
            toast.error(response.error);
            return;
        }
        if (response.user) {
            toast.success(response.message);
            dispatch(updateAuth(true));
            dispatch(updateUserDetails(response.user));
            navigate("/app/dashboard");
        }
    };
    const handleForgotPassword = async () => {
        if (!email.trim()) {
            toast.warn("Kindly enter the email ID first");
            return;
        }

        const response = await postData("forgotPassword", { email, password });

        if (response.error) {
            toast.error(response.error);
            return;
        }

        if (response.message) {
            toast.success(response.message);
        }
    };
    useEffect(() => {
        if (auth) navigate("/app/dashboard");
    }, [navigate]);
    return (
        <form onSubmit={handleEmailLogin} method="POST">
            <div className="authLeft">
                <div className="authPopup">
                    <div className="authPopupHeading">
                        Sign in to your account
                    </div>
                    <div className="authSocial">
                        <div
                            className="authSocialBtns"
                            onClick={handleGoogleAuth}
                        >
                            <img src={Google} />
                            <span>Sign in with Google</span>
                        </div>
                        <div className="emailSigninHead">
                            <div className="line"></div>
                            <span>or sign in with email</span>
                            <div className="line"></div>
                        </div>
                    </div>
                    <div className="authSocial">
                        <div className="authInput">
                            <p>Email Address</p>
                            <input
                                type="text"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="authInput">
                            <p>Password</p>
                            <div className="passwordInput">
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    placeholder="Enter your password"
                                    style={{
                                        paddingRight: "40px",
                                        width: "100%",
                                    }}
                                    required
                                />
                                {passwordVisible ? (
                                    <LuEyeOff onClick={togglePasswordVisibility} />
                                ) : (
                                    <LuEye onClick={togglePasswordVisibility} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="floatRight" onClick={handleForgotPassword}>
                        Forgot password?
                    </div>
                    <button className="authSignin" type="submit">
                        Sign in
                    </button>
                    <p className="authNewText">
                        New around here? &nbsp;
                        <span onClick={() => setShowLogin(false)}>
                            Signup for Free
                        </span>
                    </p>
                </div>
            </div>
        </form>
    );
}

export default LoginComponent;
