import styles from "./about.module.css";
import Contact from "./Contact";
function About() {
  return (
    <div className={styles.aboutContainer}>
      <div className={styles.topSection}>
        <div className={styles.leftSection}>
          <img
            style={{ width: "80%", marginTop: "20px" }}
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/853b01023706ab34cd96b77d3c0c337cc8f9e8ef86ca5d40c0f3fa410bcf9776?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
            alt=""
          />
        </div>
        <div className={styles.rightSection}>
          <h1>About Friday!</h1>
          <p>
            Welcome to the National Hookah Community Association "NHCA". We are
            a diverse alliance of businesses from all ends of the Hookah
            experience, from manufacturers and importers of molasses, pipes and
            accessories to distributors, Hookah lounges and Hookah/shisha retail
            stores.
          </p>
          <div className={styles.line}></div>
          <p>
            Welcome to the National Hookah Community Association "NHCA". We are
            a diverse alliance of businesses from all ends of the Hookah
            experience, from manufacturers and importers of molasses, pipes and
            accessories to distributors, Hookah lounges and Hookah/shisha retail
            stores. Welcome to the National Hookah Community Association "NHCA".
            We are a diverse alliance of businesses from all ends of the Hookah
            experience, from manufacturers and importers of molasses, pipes and
            accessories to distributors, Hookah lounges and Hookah/shisha retail
            stores.
          </p>
        </div>
      </div>
      <div className={styles.mission}>
        <div className={styles.leftSection}>
          <p className={styles.howFn}>HOW WE FUNCTION</p>
          <h2 id={styles.missionTitle}>MISSION</h2>
          <p>
            Welcome to the National Hookah Community Association "NHCA". We are
            a diverse alliance of businesses from all ends of the Hookah
            experience, from manufacturers and importers of molasses, pipes and
            accessories to distributors, Hookah lounges and Hookah/shisha retail
            stores.
            <br />
            <br />
            If you are a Hookah business, please join us and help us defend and
            protect your business, our culture and community.
            <br />
            <br />
            If you are an interested member of the public, welcome. Please enjoy
            learning about Hookah and its unique culture and practice.
          </p>
        </div>
        <div className={styles.rightSection}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/853b01023706ab34cd96b77d3c0c337cc8f9e8ef86ca5d40c0f3fa410bcf9776?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499" />
        </div>
      </div>
      <div className={styles.career}>
        <div className={styles.careerTop}>
          <p>BECOME ONE OF US!</p>
          <h2>Career Opportunities</h2>
          <p>
            The National Hookah Community Association (NHCA) is a 501(c)6
            non-profit organization that protects and serves its members who are
            facing threats from new legislation at the local, state, and federal
            level.
          </p>
        </div>
        <div className={styles.opp}>
          <div className={styles.opp1}>
            <h4>Visual Designer</h4>
            <p>
              Experience: 2-3 years
              <br />
              <br />
              Members will receive regular updates on pending legislation and
              regulations affecting hookah and hookah related products through
              state and federal news updates. Members will also be provided the
              most relevant news and insights for improving your business and
              informing the community.
            </p>
          </div>
          <div className={styles.opp1}>
            <h4>Visual Designer</h4>
            <p>
              Experience: 2-3 years
              <br />
              <br />
              Members will receive regular updates on pending legislation and
              regulations affecting hookah and hookah related products through
              state and federal news updates. Members will also be provided the
              most relevant news and insights for improving your business and
              informing the community.
            </p>
          </div>
          <div className={styles.opp1}>
            <h4>Visual Designer</h4>
            <p>
              Experience: 2-3 years
              <br />
              <br />
              Members will receive regular updates on pending legislation and
              regulations affecting hookah and hookah related products through
              state and federal news updates. Members will also be provided the
              most relevant news and insights for improving your business and
              informing the community.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.history}>
        <div className={styles.leftSection}>
          <p>HOW WE CAME INTO BEING</p>
          <h2>History</h2>
          <p>
            Welcome to the National Hookah Community Association "NHCA". We are
            a diverse alliance of businesses from all ends of the Hookah
            experience, from manufacturers and importers of molasses, pipes and
            accessories to distributors, Hookah lounges and Hookah/shisha retail
            stores.
            <br />
            <br />
            If you are a Hookah business, please join us and help us defend and
            protect your business, our culture and community.
            <br />
            <br />
            If you are an interested member of the public, welcome. Please enjoy
            learning about Hookah and its unique culture and practice.
          </p>
        </div>
        <div className={styles.rightSection}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/853b01023706ab34cd96b77d3c0c337cc8f9e8ef86ca5d40c0f3fa410bcf9776?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499" />
        </div>
      </div>
      <div className={styles.team}>
        <h2>Meet our team</h2>
        <p id={styles.teamText}>
          Наша команда состоит из высококлассных специалистов с международным
          опытом работы в сфере Web-разработки, мобильной разработки, Product
          менеджмента и дизайна. Мы предоставляем премиум сервис по доступным
          ценам и всегда нацелены на успех наших клиентов.
        </p>
        <div className={styles.members}>
          <div className={styles.memberCard}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d2e1dda72697d532a023e1d223dab17c19bdc877f1f676e8aad98a7e94db1b9?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.memberImage}
            />
            <h3 className={styles.memberName}>Name</h3>
            <p className={styles.memberRole}>Frontend Developer</p>
          </div>
          <div className={styles.memberCard}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d2e1dda72697d532a023e1d223dab17c19bdc877f1f676e8aad98a7e94db1b9?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.memberImage}
            />
            <h3 className={styles.memberName}>Name</h3>
            <p className={styles.memberRole}>Frontend Developer</p>
          </div>
          <div className={styles.memberCard}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d2e1dda72697d532a023e1d223dab17c19bdc877f1f676e8aad98a7e94db1b9?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.memberImage}
            />
            <h3 className={styles.memberName}>Name</h3>
            <p className={styles.memberRole}>Frontend Developer</p>
          </div>
          <div className={styles.memberCard}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d2e1dda72697d532a023e1d223dab17c19bdc877f1f676e8aad98a7e94db1b9?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.memberImage}
            />
            <h3 className={styles.memberName}>Name</h3>
            <p className={styles.memberRole}>Frontend Developer</p>
          </div>
          <div className={styles.memberCard}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d2e1dda72697d532a023e1d223dab17c19bdc877f1f676e8aad98a7e94db1b9?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.memberImage}
            />
            <h3 className={styles.memberName}>Name</h3>
            <p className={styles.memberRole}>Frontend Developer</p>
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
}

export default About;
