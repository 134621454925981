import React from 'react'
import "./onboardingPopup.css";
import Confetti from "react-confetti";
import { useSelector } from 'react-redux';
import { CgClose } from 'react-icons/cg';

function OnboardingPopup({ setShowOnboardingPopup }) {
    const user = useSelector(state => state.user.userDetails);
    const width = window.outerWidth;
    const height = window.outerHeight;

    return (
        <>
            <div className="containerOverlay">
                <Confetti gravity={0.05} width={width} height={height} />
            </div>
            <div className='onboardingPopupContainer'>
                <div className='onboardingPopupHead'>
                    <p>Congrats {user.name}! Welcome to</p>
                    <p className='app'>KWIKZY</p>
                    <CgClose
                        className="onboardingPopupCancelIcon"
                        onClick={() => setShowOnboardingPopup(false)}
                    />
                </div>
                <div className='onboardingPopupContent'>
                    <h2>Here’s your new organisation, with Kwikzy you can:</h2>
                    <ul >
                        <li>Share content with brands and clients</li>
                        <li>Assign content to campaigns</li>
                        <li>Auto post and repurpose content</li>
                        <li>Generate custom captions and hashtags</li>
                    </ul>
                </div>
            </div>
        </>

    )
}

export default OnboardingPopup
