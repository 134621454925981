import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";

const Protected = ({ children }) => {
    const auth = useSelector((state) => state.user.auth);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (auth) {
            setLoading(false);
        } else {
            navigate("/login");
        }
    }, [auth]);

    return loading ? <Loader/> : auth ? children : null;
};

export default Protected;
