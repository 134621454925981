import "./calendar.css";
import backgroundImage from "../../img/calendar.png";

const Calendar = () => {
  return (
    <div className="container sidebarContainer">
      <div
        className="background"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="text-container">
        <h1 className="title">Calendar</h1>
        <p className="description">
          Plan and organize your social media posts <br /> with a comprehensive
          content calendar.
        </p>
      </div>
    </div>
  );
};

export default Calendar;
