const isDevelopment = process.env.NODE_ENV === "development";

const clearUserDetails = ()=>{
  localStorage.removeItem("workspace");
  localStorage.removeItem("organisation");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("auth");
}

export const postData = async (path, data) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/server/${path}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    if (!response.ok) {
      if (result.status === 401) {
        clearUserDetails()
      }
      return { error: result.message };
    }
    return result;
  } catch (error) {
    if (isDevelopment) {
      console.error("Error in postData:", error);
    }
    return { error: "Something went wrong" };
  }
};

export const getData = async (path) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/server/${path}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      if (result.status===401){
        clearUserDetails();
      }
      return { error: result.message };
    }
    return result;
  } catch (error) {
    if (isDevelopment) {
      console.error("Error in getData:", error);
    }
    return { error: "Something went wrong" };
  }
};
