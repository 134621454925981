import "./invoicing.css";

const Invoicing = () => {
    return (
        <>
            <div className="invoiceSidebarContainer">
                <div className="invoicingHeader">
                    <h1>Invoice List</h1>
                    <a href="/invoice/template">
                        <button>Create New</button>
                    </a>
                </div>
                <div className="invoiceList">
                    <table>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Customer</th>
                            <th>Invoice Date</th>
                            <th>Due Date</th>
                            <th>Total Amount</th>
                            <th>Download/Share</th>
                        </tr>
                        <tr>
                            <td>Invoice Number</td>
                            <td>Customer</td>
                            <td>Invoice Date</td>
                            <td>Due Date</td>
                            <td>Total Amount</td>
                            <td>Download/Share</td>
                        </tr>
                        <tr>
                            <td>Invoice Number</td>
                            <td>Customer</td>
                            <td>Invoice Date</td>
                            <td>Due Date</td>
                            <td>Total Amount</td>
                            <td>Download/Share</td>
                        </tr>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Invoicing;
