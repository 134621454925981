import React from "react";
import "./testimonials.css";
import { ImQuotesLeft } from "react-icons/im";

const stories = [
  {
    content: "Your app brings so much peace and tolerance to our home.",
    author: "Rachael, UK",
    description: "on meditation's positive effect on family life",
  },
  {
    content: "I came to learn that the storyline in my head was holding me back.",
    author: "Peter, Belgium",
    description: "on what he learned when sitting with himself",
  },
  {
    content: "Headspace provides me with a connection to myself, and a disconnection from negative thoughts, feelings, and sensations.",
    author: "Keri, UK",
    description: "on finding her happy place",
  },
  {
    content: "Changing my thoughts has allowed me to change my life.",
    author: "Davide, London",
    description: "on using meditation to turn his life around",
  },
  {
    content: "Changing my thoughts has allowed me to change my life.",
    author: "Davide, London",
    description: "on using meditation to turn his life around",
  },
];

const Testimonials = () => {
  return (
    <div className="testimonialContainer">
      <h1 className="testimonialHeading">Testimonials</h1>
      <div className="testimonialCorouselContainer">
        <div className="testimonialInnerContainer">
          {stories.map((story, index) => (
            <div className="testimonialCard" key={index}>
              <ImQuotesLeft className="testimonialIcon" />
              <div className="testimonialText">{story.content}</div>
              <footer className="testimonialFooter">
                <div className="testimonialAuthor">{story.author}</div>
                <div className="testimonialCaption">{story.description}</div>
              </footer>
            </div>
          ))}
          {/* Duplicate the cards for seamless scrolling */}
          {stories.map((story, index) => (
            <div className="testimonialCard" key={index + stories.length}>
              <ImQuotesLeft className="testimonialIcon" />
              <div className="testimonialText">{story.content}</div>
              <footer className="testimonialFooter">
                <div className="testimonialAuthor">{story.author}</div>
                <div className="testimonialCaption">{story.description}</div>
              </footer>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
