import React from "react";
import styles from './about.module.css'

const Contact = () => {
  return (
    <div className={styles.contactContainer}>
      <div className={styles.contactInfo}>
        <div className={styles.contactLeft}>
          <h2>Contact Information</h2>
          <p className={styles.subtitle}>Say something to start a live chat!</p>
          <div className={styles.contactItem}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec6bc6247aab00a486a3bf07e59c9c57e971aa044e6e2262dd14aa13f871aec9?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.icon}
            />
            <div className={styles.contactText}>+1012 3456 789</div>
          </div>
          <div className={styles.contactItem}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/329025495c1a7369b7de5bab1dfa20a6268a297e29930265ffa45ceb24f13901?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.icon}
            />
            <div className={styles.contactText}>demo@gmail.com</div>
          </div>
          <div className={styles.contactItem}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a4539d99acd13b742519579f4b8b913d231e4fd1baf2fd212692c81280111ff8?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
              alt=""
              className={styles.icon}
            />
            <div className={styles.addressText}>
              132 Dartmouth Street Boston, Massachusetts 02156 United States
            </div>
          </div>
          <div className={styles.socialContainer}>
            <div className={styles.socialWrapper}>
              <div className={styles.socialIconsColumn}>
                <div className={styles.socialIcons}>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/9922bb6276edac19003c81fe4d1d9e3bcfdffc4a8259426bede15d94573c4a0b?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
                    alt="Social Icon 1"
                    className={styles.socialIcon}
                  />
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e0a4158-33d4-411d-8326-f7545d1b7134?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
                    alt="Social Icon 2"
                    className={styles.socialIconTall}
                  />
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a4ff13e16d7acaa69144dc34acbaed1d9f24d1d96b50269eeadef2e5cf829f5f?placeholderIfAbsent=true&apiKey=67531bd3bcf3486eb21b1f677fd9a499"
                    alt="Social Icon 3"
                    className={styles.socialIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contactRight}>
          <form>
            <div className={styles.inputCol}>
              <div className={styles.formGroup}>
                <label for="firstName">First Name</label>
                <input type="text" id="firstName" placeholder="Pawan" />
              </div>
              <div className={styles.formGroup}>
                <label for="lastName">Last Name</label>
                <input type="text" id="lastName" placeholder="Rawat" />
              </div>
            </div>
            <div className={styles.inputCol}>
              <div className={styles.formGroup}>
                <label for="firstName">Email</label>
                <input type="text" id="firstName" placeholder="Email" />
              </div>

              <div className={styles.formGroup}>
                <label for="email">Phone</label>
                <input type="text" id="email" placeholder="Phone" />
              </div>
            </div>
            <div style={{ width: "100%", marginTop: "20px" }}>
              <h4>Select Subject?</h4>
              <div className={styles.radioInputCol}>
                <input type="radio" name="subject" id="" />
                <label className={styles.radioLabel}>General Enquiry</label>
                <input type="radio" name="subject" id="" />
                <label className={styles.radioLabel}>General Enquiry</label>
                <input type="radio" name="subject" id="" />
                <label className={styles.radioLabel}>General Enquiry</label>
                <input type="radio" name="subject" id="" />
                <label className={styles.radioLabel}>General Enquiry</label>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: "20px" }}>
              <label style={{ color: "#8D8D8D" }}>Message</label>
              <input
                className={styles.singleLineTextarea}
                placeholder="Enter your message"
              ></input>
            </div>
            <button id={styles.messageBtn}>Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
