exports.generateInvoiceHTML = (invoiceData) => {
    const currency = {
        INR: "₹",
        USD: "$",
        EURO: "€",
        POUND: "£",
    };

    const itemsHTML = invoiceData.items
        .map((item, index) => {
            const amount = (
                item.quantity * item.price +
                (item.tax / 100) * (item.quantity * item.price)
            ).toLocaleString();

            return `
                <tr>
                    <td><p>${index + 1}</p></td>
                    <td><div class="invoiceItemsItemName"
                    >${item.name}</div></td>
                    <td><input type="number" value="${item.quantity}" /></td>
                    <td><input type="number" value="${item.price}" /></td>
                    <td><input type="text" value="${item.tax ? item.tax + '%' : ''}" /></td>
                    <td><p>${amount}</p></td>
                    <td></td>
                </tr>`;
        })
        .join("");

    const subTotal = invoiceData.items.reduce(
        (acc, val) => acc + val.price * val.quantity,
        0
    );

    const discountValue = (invoiceData.discount / 100) * subTotal;

    const taxAmount = invoiceData.items.reduce(
        (acc, val) =>
            acc +
            (val.tax / 100) *
                (val.price * val.quantity -
                    (invoiceData.discount / 100) * val.price * val.quantity),
        0
    );

    const totalPayable = subTotal + taxAmount -discountValue;

    return `
            <div class="invoiceContainer" style={width: 100%; margin-top:500px}>
                <div class="invoiceHeader">
                <div class="invoiceHeaderDetails">
                <div>
                <h3>Customer Invoice</h3>
                        <input value="${
                            invoiceData.invoiceNumber
                        }" class="invoiceNumber" />
                </div>

                        <div class="invoiceHeaderDates">
                            <label>
                                Currency
                                <select style='border:none; padding: 0 ' value="${invoiceData.currency}">
                                    <option value="INR" ${
                                        invoiceData.currency === "INR"
                                            ? "selected"
                                            : ""
                                    }>INR ₹</option>
                                    <option value="USD" ${
                                        invoiceData.currency === "USD"
                                            ? "selected"
                                            : ""
                                    }>USD $</option>
                                    <option value="EURO" ${
                                        invoiceData.currency === "EURO"
                                            ? "selected"
                                            : ""
                                    }>EURO €</option>
                                    <option value="POUND" ${
                                        invoiceData.currency === "POUND"
                                            ? "selected"
                                            : ""
                                    }>POUND £</option>
                                </select>

                            </label>
                            <label>
                                Invoice Date
                                <input type="text" style="border:none; padding: 0 " value="${
                                    invoiceData.invoiceDate ? invoiceData.invoiceDate : 'NA'
                                }" disabled />
                            </label>
                            <label>
                                Due Date
                                <input type="text" style="border:none; padding: 0 " value="${
                                    invoiceData.dueDate ? invoiceData.dueDate : 'NA' 
                                }" disabled />
                            </label>
                        </div>
                    </div>
                </div>


                <div class="invoiceParties">
                    <div class="invoiceParty">
                        <h3>Billed By:</h3>
                        <div class="invoicePartyDetails">
                            
                            <input
                                type="text"
                                value="${invoiceData.from["BusinessName"]}"
                            />
                            <input
                                type="text"
                                value="${invoiceData.from["Name"]}"
                            />
                            <div class="invoicePartyDetailsHalfInputContainer">
                                <input
                                    type="number"
                                    value="${invoiceData.from["Phone"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                                <input
                                    type="text"
                                    value="${invoiceData.from["GSTIN"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                            </div>
                            <input
                                type="text"
                                value="${invoiceData.from["Email"]}"
                            />
                            <input
                                type="text"
                                value="${invoiceData.from["Address"]}"
                            />
                            <div class="invoicePartyDetailsHalfInputContainer">
                                <input
                                    type="text"
                                    value="${invoiceData.from["City"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                                <input
                                    type="text"
                                    value="${invoiceData.from["State"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                            </div>
                            <input
                                type="text"
                                value="${invoiceData.from["Country"]}"
                            />
                        </div>
                    </div>
                    <div class="invoiceParty">
                        <h3>Billed To:</h3>
                        <div class="invoicePartyDetails">
                            
                            <input
                                type="text"
                                value="${invoiceData.to["BusinessName"]}"
                            />
                            <input
                                type="text"
                                value="${invoiceData.to["Name"]}"
                            />
                            <div class="invoicePartyDetailsHalfInputContainer">
                                <input
                                    type="number"
                                    value="${invoiceData.to["Phone"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                                <input
                                    type="text"
                                    max-length="15"
                                    value="${invoiceData.to["GSTIN"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                            </div>
                            <input
                                type="text"
                                value="${invoiceData.to["Email"]}"
                            />
                            <input
                                type="text"
                                value="${invoiceData.to["Address"]}"
                            />
                            <div class="invoicePartyDetailsHalfInputContainer">
                                <input
                                    type="text"
                                    value="${invoiceData.to["City"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                                <input
                                    type="text"
                                    value="${invoiceData.to["State"]}"
                                    class="invoicePartyDetailsHalfInput"
                                />
                            </div>
                            <input
                                type="text"
                                value="${invoiceData.to["Country"]}"
                            />
                        </div>
                    </div>
                </div>


                <div class="invoiceItems">
                    <h3>Item details</h3>
                    <table>
                            <tr>
                                <th>S. No.</th>
                                <th>Item Name</th>
                                <th>Units</th>
                                <th>Price</th>
                                <th>Tax(%)</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                            ${itemsHTML}
                    </table>
                </div>

                <div class="invoiceCalculations">
                    <div class="invoiceNote">
                    ${invoiceData.notes ? `
                        <h3>Notes</h3>
                        <div
                            class="invoiceNoteText"
                            style="border:none"
                        >${invoiceData.notes}</div>` : ""}
                    </div>

                    <div class="invoiceAmounts">
                        <p>Sub Total</p><div> ${subTotal.toLocaleString()}</div>
                        <p>
                            Discount
                            <input
                                type="text"
                                class="invoiceDiscount"
                                style="display:${invoiceData.discount ? '' : 'none'}; border: none"
                                value="${invoiceData.discount ? invoiceData.discount + '%' : ''}"
                            ></input>
                        </p>
                        <div class="invoiceDiscountValue">
                        ${discountValue.toLocaleString()}
                        </div>
                        <p>Tax Amount</p> <div> ${taxAmount.toLocaleString()} </div>
                        <p>Total Payable</p> <div>${
                            currency[invoiceData.currency]
                        }${totalPayable.toLocaleString()}</div>
                    </div>
                    
                </div>

                
            </div>
    `;
};
