import React from 'react'
import FeatureItem from './FeatureItem';
import styles from './pricing.module.css';

const features = [
    { text: "Create personal dashboard.", isHighlighted: false },
    { text: "Organize your notes and workflows.", isHighlighted: false },
    { text: "5GB of space.", isHighlighted: true }
];

const proFeatures = [
    { text: "Create personal dashboard.", isHighlighted: false },
    { text: "Organize your notes and workflows.", isHighlighted: false },
    { text: "5GB of space.", isHighlighted: true }
];

function Card({cardDetails}) {
    const plan = cardDetails.title != "Pro" ? "card1" : "card2";
  return (
      <div className={`${styles.card} ${styles[plan]}`}>
          {cardDetails.discount && <div className={styles.saleTag}>{cardDetails.discount}</div>}
          <header className={styles.header}>
              <h2 className={styles.title}>{cardDetails.title}</h2>
              <div className={styles.priceContainer}>
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/caa169d0e6da1e130664bc320331584a1c701053c226e90dcd0f57ba9d19b60b?apiKey=67531bd3bcf3486eb21b1f677fd9a499&&apiKey=67531bd3bcf3486eb21b1f677fd9a499" alt="" className={styles.currencyIcon} />
                  <span className={styles.price}>{cardDetails.price}</span>
                  <span className={styles.priceUnit}>{cardDetails.unit}</span>
              </div>
          </header>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/55e9e0d4d78a2964b5684945117a48db4fb79f0d225c7d4df35a8dd65a0232f8?apiKey=67531bd3bcf3486eb21b1f677fd9a499&&apiKey=67531bd3bcf3486eb21b1f677fd9a499" alt="Personal plan features illustration" className={styles.featureImage} />
          <div className={styles.featureList}>
              {features.map((feature, index) => {
                  const featureProps = plan === "card1" ? { ...feature } : { ...proFeatures };
                      return < FeatureItem key = { index } { ...feature } plan = { plan } />
              }
              )}
          </div>
          <button className={styles.ctaButton}>
              <span className={styles.ctaText}>Choose this plan</span>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/aef84f8769f5879983ae8997f24a6727b6f377af20f072057037ad2f75d397e1?apiKey=67531bd3bcf3486eb21b1f677fd9a499&&apiKey=67531bd3bcf3486eb21b1f677fd9a499" alt="" className={styles.ctaIcon} />
          </button>
      </div>
  )
}

export default Card
