import Select from "react-select";
import CustomOption, { CustomSingleValue } from "./CustomOption";

export default function CustomDropdown({
    selectedOption,
    setSelectedOption,
    options,
    placeholder
}) {
    return (
        <Select
            classNames={{
                control: () => "postPopupInputBox-control",
                menuList: () => "postPopupInputBox-menu",
                menu: () => "postPopupInputBox-menu",
            }}
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            placeholder={placeholder}
            components={{
                Option: CustomOption,
                SingleValue: CustomSingleValue
            }}
        />
    );
}
